"use strict"

// HTML elements that this file uses:
// .cyphrStars
// #reviewForm
// #commentSection

import * as Login from './login.js'
import * as Cyphrme from './cyphrme.js'
import * as CVA from './cva.js'
import * as Ajax from './ajax.js'
import * as Pag from './paginate.js'
import * as Lib from '../lib/lib~fv=LNbMt1n5.min.js'
import '../pkg/urlform~fv=VhWyOSvq.min.js' // Namespaced as 'URLForm'.

/**
@typedef {import('../../../pkg/cozejs/typedef.js').B64} B64
@typedef {import('../../../pkg/cozejs/typedef.js').Pay} Pay
@typedef {import('./paginate.js').Paginate}             Paginate
@typedef {import('./paginate.js').PagRec}               PagRec
 */

/**
Comment holds fields for a comment/review.

- id: The B64 ID of the comment (from the coze).
- uad: The B64 ID of the user that owns/created the comment.
- text: The text contents of the comment.
- time: The time the comment was first seen by Cyphr.me.
- parent: The parent that the comment is attached to.
- root: The page the comment was originally made from.
- child_ac: The ID of the model's child.
- edit: Whether or not the comment is an edit or a reply.
- modelID: The Model ID, if a comment is attached to a model page.
- displayName: The comment owner's display name.
- images: Any images attached to the comment by the comment owner. // TODO
- rating: The number of rating "Points" as given by the user.
- ratingDenom: The Rating's denominator. The scale of the rating. (e.g 5 for 1/5
  or 5/5).
- counterfeit: Whether or not the comment is serving as a counterfeit report.
- purchased: Whether or not the user that scanned the item purchased the item.
- purhcaseLocation: The location that the product was purchased from as reported
  by the user.
- children: Any children comments (replies) on this comment.
@typedef  {object}     Comment 
@property {B64}        id
@property {B64}        uad
@property {string}     text
@property {number}     time
@property {B64}        root
@property {B64}        [parent]
@property {B64}        [child_ac]
@property {boolean}    [edit]
@property {B64}        [modelID]
@property {string}     [displayName]
@property {B64[]}      [images]       - TODO
@property {number}     [rating]
@property {number}     [ratingDenom]
@property {boolean}    [counterfeit]
@property {boolean}    [purchased]
@property {string}     [purchaseLocation]
@property {Comment[]}  [children]
 */

/**
CommentPay are the signable fields on a Comment/Review. Standard Coze fields
should be prepended to a CommentPay to be a valid Coze.

Required fields:
- root:  The root subject that the comment is attached to.

Fields marked with ** mean that if present, the comment is considered to be a
review.

Optional fields:
- id: ID of the comment, when being updated/edited.
- text: The text area value.
- parent: The ID that the comment is directly attached to. e.g. another
  comment's ID.
- child_ac: The ID of a model's child.
- counterfeit_report: True if user reporting the current page as a counterfeit.
- rating: The user's rating out of 5 for the given page.
- rating_denom: Rating denom (5 for 0/5). Uses default page denom if rating is
  given.
- purchase_location: The purchase location for where a use may have purchased
  the item for the given page.
- purchased: Whether or not the user on the page has purchased the product that
  the page is for.
- edit: True if a user is editing a given comment/review. (Set by application)
- rtd: (reply to digest) The digest of the text of the comment being replied to.
@typedef  {object}   CommentPay
@property {B64}      root
@property {B64}      [id]
@property {B64}      [parent]
@property {B64}      [child_ac]
@property {string}   [text]
@property {boolean}  [counterfeit_report]
@property {number}   [rating]
@property {number}   [rating_denom]
@property {string}   [purchase_location]
@property {boolean}  [purchased]
@property {boolean}  [edit]
@property {boolean}  [rtd]
 */

/**
EditableReview holds review fields for updating a review. If any of the
optional fields are present, then `is_review` is set to true.

is_review: Whether the comment has any optional review fields.

element: The HTML element for the comment/review.

All other optional fields are the same as `CommentPay`.
@typedef  {object}   EditableReview
@property {boolean}  is_review
@property {Element}  element
@property {number}   [rating]
@property {boolean}  [purchased]
@property {string}   [purchase_location]
@property {boolean}  [counterfeit]
 */

export {
	CreateReviews,
	CommentReviewModule,
	CreateSubmitReviewForm,
	SetPageRatingStar,
	SubmitReview,
	SubmitComment,
	ToggleReviewForm,
}

// global for callbacks
/** @type {PagRec} */
var PR = {}

// URLFormJS form options
var initedFormOptions

/**
CommentReviewModule inits the ReviewComment module.
Creates the Comments, and displays them on the page.
@param   {PagRec} pr
@returns {void}
 */
async function CommentReviewModule(pr) {
	// console.log("CommentReviewModule:", pr)

	PR = pr // set global
	let cmnt = document.getElementById('CommentSection')
	cmnt.append(CreateReviews(PR.rec))
	if (isEmpty(PR.rec)) {
		PR.rec = []
	}
	// Show submitted review if logged in and not submitted review. // TODO fix show when review is on page 2. 
	if (!isEmpty(Login.UAD)) {
		let subed = false
		for (let rec of PR.rec) {
			if (rec.uad == Login.UAD) {
				subed = true
				break
			}
		}
		if (!subed) {
			Show(document.getElementById("reviewFormDiv"))
		}
	}

	// "Show more comments" button on Comment Threads
	if (PR.pag.has_more) {
		let btn = document.querySelector('#showMoreCommentsBtn')
		if (btn != null) {
			btn.href = Cyphrme.Page.Comments + "/" + PR.pag.pivot + "?page=2"
			Show(btn)
		}
	}

	if (PR.pag.lock_comments) {
		Show("lockCommentsOnPage")
		Disable("submitReviewBtn")
	}

	// Init URLFormJS.
	initedFormOptions = URLForm.Init(URLForm.DefaultFormOptions)

	Show(cmnt)
}


/**
CreateReviews creates all reviews and comments for a page and returns and
HTML div, with formatting.  Review comment children are included recursively.
For each comment, the process goes: Review -> Comment -> Comment Children,
and then returns such tree in divs, ready to append to the main thread.
@param   {Comment[]} revs  Review/Comments, with comment children.
@returns {Element}
 */
function CreateReviews(revs) {
	if (isEmpty(revs)) {
		let div = document.getElementById('noCommentsOnPage')
		Show(div)
		return div
	}
	// Range through each 'top-level' comment, to create the individual comment's
	// thread. If the comment is a review, the review fields are populated.
	let div = document.createElement('div')
	for (let rev of revs) {
		div.append(createReview(rev))
	}
	return div
}


/**
appendChild accepts a div, and a slice of children, to then create a comment for
each and then return the div. Also handles pagination for comment depth, and
create a show more link on the comment once it reaches the specified depth.

Helper function to avoid recursive calls on createComment.
@param   {Element} div      Div of the thing to append the children to.
@param   {Comment} comment  Comment/review including any children.
@returns {Element}
 */
function appendChildren(div, comment) {
	// console.debug(div)
	let depth = comment.children.length
	if (comment.has_more_children) {
		depth = comment.children.length - 1
		let link = div.querySelector(".showMoreCommentsLink")
		let current = Pag.GetCurrentURLParams()
		current.params += "&page=" + (current.page + 1)
		if (!isEmpty(current.limit)) {
			current.params += "&limit=" + current.limit
		}
		if (current.order) {
			current.params += "&order=true&piv_comop=gteq"
		}
		// console.debug('urlParams:', current.params)
		// console.debug('current:', current)
		link.href = Cyphrme.Page.Comments + "/" + comment.children[depth].id + "?" + current.params
		Show(link)
	}
	for (var i = 0; i < depth; i++) {
		let child = createComment(comment.children[i])
		div.querySelector(".children").append(child)
	}
	return div
}

/**
createReview returns a populated review card with children. 
@param   {Comment} comment
@returns {Element}
 */
function createReview(comment) {
	let card = createComment(comment)
	let ce = card.querySelector('.comment_extra')
	createExtraAppend(ce, "Rating", comment.rating)
	createExtraAppend(ce, "Purchased", isBool(comment.purchased))
	createExtraAppend(ce, "Purchase Location", comment.purchase_location)
	createExtraAppend(ce, "Counterfeit", comment.counterfeit_report)
	return card
}

/**
createExtraAppend generates a span element with a Key:Value pair.
If empty,nothing happens.
@param   {Element} Element  HTMLElement being appended to.
@param   {string}  Name     Class name with removed whitespace for adding to span.
@param   {string}  Value    Value of the span
@returns {void}
 */
function createExtraAppend(Element, Name, Value) {
	if (isEmpty(Value)) {
		return
	}
	let s = document.createElement('span')
	let c = Name.replace(/\s/g, '') // remove whitespace from name
	s.classList.add(c)
	s.innerText = " | " + Name + ": " + Value
	s.dataset.value = Value // Used for editable reviews/comments.
	Element.append(s)
}

/**
createComment returns a populated comment card.
@param   {Comment} comment
@returns {Element}
 */
function createComment(comment) {
	let template = document.getElementById('commentTemplate')
	let commentDiv = template.content.cloneNode(true)
	let card = commentDiv.querySelector('.card')

	if (comment.deleted) {
		card.querySelector('.text-muted').remove()
		card.querySelector('.comment').textContent = " Comment has been deleted"
		card.querySelector('.buttons').remove()
		card.classList.add('border-warning', 'text-start')
		if (!isEmpty(comment.children)) {
			card = appendChildren(card, comment)
		}
		return card
	}

	let replyBtn = card.querySelector('.commentReplyBtn')
	let editBtn = card.querySelector('.commentEditBtn')
	let deleteBtn = card.querySelector('.commentDeleteBtn')

	card.setAttribute('data-commentID', comment.id)
	card.setAttribute('id', comment.id)

	let options = {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
		hour: 'numeric',
		minute: 'numeric'
	}
	let createdDate = new Date(comment.time * 1000).toLocaleString("en-US", options)
	let updatedDate = new Date(comment.updated * 1000).toLocaleString("en-US", options)
	if (isEmpty(comment.updated) || comment.updated == 0) {
		updatedDate = 'Never'
		Hide(card.querySelector('.updated').parentElement)
	}
	let ownerLink = ""
	if (!isEmpty(comment.display_name)) {
		ownerLink = comment.display_name
	} else {
		ownerLink = comment.uad
	}
	if (ownerLink.length > 10) {
		ownerLink = ownerLink.substring(0, 10) + "..."
	}
	let text = comment.text
	if (isEmpty(text)) {
		text = '[no text]'
	}
	card.querySelector('.comment').innerText = text
	card.querySelector('.owner').innerText = ownerLink
	card.querySelector('.owner').href = '/user/id/' + comment.uad
	card.querySelector('.created').innerText = createdDate
	card.querySelector('.updated').innerText = updatedDate
	card.querySelector('.commentRaw').innerText = JSON.stringify(comment, null, " ")
	card.querySelector('.commentLink').setAttribute('href', Cyphrme.Page.Comments + "/" + comment.id)

	Cyphrme.Collapse(card.querySelector(".bi-dash-square"), card.querySelector('.card-hide'))

	// Hide Reply, if not logged in.
	if (isEmpty(Login.UAD)) {
		Hide(replyBtn)
	} else {
		replyBtn.addEventListener('click', function () {
			commentReplyOrEdit(card, false)
		})
	}
	if (PR.pag.lock_comments) {
		Disable(replyBtn)
	}
	// Show buttons if owner.
	if (Login.UAD == comment.uad) {
		editBtn.addEventListener('click', function () {
			commentReplyOrEdit(card, true)
		})
		deleteBtn.addEventListener('click', function () {
			commentDelete(card)
		})
		Show(editBtn)
		Show(deleteBtn)
	}

	// Create and append children, if they exist.
	if (!isEmpty(comment.children)) {
		card = appendChildren(card, comment)
	}
	return card
}

/**
commentReplyOrEdit creates a new reply div on the page or toggles existing ones.
Also used for editing comments. Reply div is inside parent.
@param   {Element} div    Comment div element on page.
@param   {bool}    [edit] True if the comment is being edited, and is not a new reply.
@returns {void}
 */
function commentReplyOrEdit(div, edit) {
	// console.log(div, edit)
	var rd = div.querySelector(".replyDiv")

	// Check if a reply exists. If so, toggle visibility.
	if (rd !== null) {
		let change = setReplyFunc(div, edit)

		if (!change || (change && !isVisible(rd))) {
			ToggleVisible(rd)
		}
		return
	}

	// Container Div for whole reply
	let templ = document.querySelector('#commentReplyDivTempl').content.cloneNode(true)
	// console.log("Templ", templ)
	let replyDiv = templ.querySelector('div')
	// console.log("Div", replyDiv)

	// TODO typedef
	var reviewObj = editableReview(div, replyDiv)
	console.debug(reviewObj)

	// For event listeners on comment submission.
	let submitFunc = async function (event) {
		event.preventDefault()
		let comment = {}
		comment.text = div.querySelector("textarea").value
		comment.id = div.getAttribute("data-commentid")
		comment.edit = div.classList.contains('edit')
		comment.root = PR.pag.subject_value

		// If comment is a review, add populated fields to pay.
		if (reviewObj.is_review) {
			comment = await setReviewPay(reviewObj.element, comment)
		}

		if (!comment.edit) {
			// Not edit means it is a reply.
			comment.parent = comment.id
		}
		// console.debug(comment)
		SubmitComment(comment)
	}

	replyDiv.querySelector('button').addEventListener('click', submitFunc)

	if (!reviewObj.is_review) {
		let textArea = replyDiv.querySelector('textarea')
		textArea.addEventListener('keydown', function (event) {
			if (event.code == "Enter" && event.shiftKey) {
				submitFunc(event)
			}
		})
		Show(textArea)
	}
	div.querySelector("div.row").append(replyDiv)
	setReplyFunc(div, edit) // Set function for reply div.
}

/**
editableReview checks if review fields are populated on the given comment
div element, and if so, assumes the comment is a review. The review form
is populated in the comment's reply div, with the review fields. Returns true
if the comment is a review, and false if just a comment/reply.
@param   {Element} div       Div of the whole comment (not the reply)
@param   {Element} replyDiv  Div for the comment's reply or review form.
@returns {object} // TODO typedef
 */
function editableReview(div, replyDiv) {
	let ratingElem = div.querySelector('.Rating')
	let purchasedElem = div.querySelector('.Purchased')
	let purchaseLocationElem = div.querySelector('.PurchaseLocation')
	let ctfElem = div.querySelector('.Counterfeit')

	let reviewObj = {
		is_review: false
	}

	let templ = document.getElementById('submitReviewTemplate')
	if (templ === null) {
		return reviewObj
	}
	let tcopy = templ.content.cloneNode(true)
	let review = tcopy.querySelector('div')

	review.querySelector('.card-header').textContent = "Edit Review"
	review.querySelector('#submitReviewBtn').remove()

	if (ratingElem !== null) {
		review.querySelector('.reviewStars').innerHTML = getStarsFromRating(ratingElem.dataset.value)
		reviewObj.is_review = true
		reviewObj.rating = ratingElem.dataset.value
	}
	if (purchasedElem !== null) {
		review.querySelector('.reviewPurchased').checked = purchasedElem.dataset.value
		reviewObj.is_review = true
		reviewObj.purchased = isTrue(purchasedElem.dataset.value)
	}
	if (purchaseLocationElem !== null) {
		review.querySelector('.reviewPurchaseLocation').value = purchaseLocationElem.dataset.value
		reviewObj.is_review = true
		reviewObj.purchase_location = purchaseLocationElem.dataset.value
	}
	if (ctfElem !== null) {
		review.querySelector('.reviewReportCounterfeit').checked = ctfElem.dataset.value
		reviewObj.is_review = true
		reviewObj.counterfeit = isTrue(ctfElem.dataset.value)
	}
	if (reviewObj.is_review) {
		replyDiv.append(review)
		var stars = review.querySelectorAll(".reviewStars span")
		stars.forEach(star => {
			star.addEventListener('click', function () { // Don't make anonymous, loses ability for `this` keyword.
				return SetRatingStar(parseInt(this.getAttribute("data-rating")), stars)
			})
		})
	}
	reviewObj.element = review
	return reviewObj
}

/**
Helper function for setting reply and edit text and action listener.
Returns whether the reply changed from an edit to a reply.
@param   {Element} div     Div of the whole comment (not the reply)
@param   {boolean} edit    Whether new reply or an edit
@returns {boolean}
 */
function setReplyFunc(div, edit) {
	edit = isBool(edit)

	let change = false
	if (
		(div.classList.contains("reply") && edit == true) ||
		(div.classList.contains("edit") && edit == false)
	) {
		change = true
	}

	let textarea = div.querySelector('textarea')
	if (edit == true) {
		textarea.value = div.querySelector('.comment').innerText
		div.classList.remove("reply")
		div.classList.add("edit")
	} else {
		// Reply
		textarea.value = ""
		div.classList.remove("edit")
		div.classList.add("reply")
	}
	return change
}

/**
CreateSubmitReviewForm generates or regenerates the review form. Returns true on
success.
@returns {boolean}
 */
function CreateSubmitReviewForm() {
	let tcopy = document.getElementById('submitReviewTemplate').content.cloneNode(true)
	let review = tcopy.querySelector('div')
	// Add class to the main review form on the page, but not the template, since
	// edits may also be using the review template, but a main class must be used
	// for finding the main form on the page.
	review.classList.add("submitReview")
	let reviewForm = document.getElementById('reviewFormDiv')
	reviewForm.innerHTML = "" // reset incase it was called before.  
	reviewForm.append(review)
	Show(reviewForm)

	///////////////////////
	// Review Star Javascript
	///////////////////////
	var stars = review.querySelectorAll(".reviewStars span")
	stars.forEach(star => {
		star.addEventListener('click', function () {
			var rating = parseInt(this.getAttribute("data-rating"))
			return SetRatingStar(rating, stars)
		})
	})

	review.querySelector('#submitReviewBtn').addEventListener('click', function (event) {
		event.preventDefault()
		SubmitReview()
	})
	// Shift + Enter should submit review.  
	review.querySelector('.reviewComment').addEventListener('keydown', function (event) {
		if (event.code == "Enter" && event.shiftKey) {
			event.preventDefault()
			SubmitReview()
		}
	})
}

// TODO not implemented. Future use.
// statefulPage resets the page set after ajax actions.
function statefulPage(page) {
	if (isEmpty(parsd.obj.rating)) {
		Hide(document.getElementById('cyphrRating').parentElement)
	}
	SetPageRatingStar(parsd.obj.rating)

	if (parsd.obj.rating != 0) {
		let ratingheader = document.querySelector('.cyphrme_rating')
		let ratingSpan = document.getElementById('cyphrRating') // TODO Rename
		// TODO Make these accessible via Page.
		// Both need to be accessible, because one is for the integer, and one is for the stars
		if (ratingSpan !== null) {
			ratingSpan.innerText = parsd.obj.rating
		}
		if (ratingheader !== null) {
			Show(ratingheader)
			Show(ratingheader.parentElement)
		}
		SetPageRatingStar(parsd.obj.rating)

	}
}

////////////////////////////////////////////////////////////////////////////////
// Ajax Section
////////////////////////////////////////////////////////////////////////////////

/**
SubmitComment Submits top-level comments, replies and edits. 'topLevel' flag is
used for submitting top level comments. See blog/md pages for example.
Throws error if necessary components are not set.
@param   {Comment}  comment
@param   {boolean}  [topLevel=false]
@returns {void}
@throws  {error}
 */
async function SubmitComment(comment, topLevel) {
	// console.debug("In SubmitComment")

	if (isEmpty(Login.CozeKey.tmb)) {
		Cyphrme.Error("No key set for this User.")
	}
	let cb = returnReplyCallback
	let coze
	let api
	if (comment.edit) {
		if (isEmpty(comment.id)) {
			throw new Error("comment.SubmitComment: Comment ID must be set on edits.")
		}
		coze = await CVA.CommentUpdate(comment)
		api = Cyphrme.API.Post.CommentUpdate
	} else {
		if (topLevel) {
			cb = async (parsd) => {
				if (isEmpty(parsd)) {
					return
				}
				if (!isEmpty(Login.AccountDetails.display_name)) {
					parsd.obj.cmt.display_name = Login.AccountDetails.display_name
				}
				document.getElementById('commentThread').prepend(createComment(parsd.obj.cmt))
			}
		} else {
			if (isEmpty(comment.parent)) {
				throw new Error("comment.SubmitComment: Comment parent must be set.")
			}
			delete comment.id
			delete comment.edit
			comment.rtd = await getCommentTextDigest(comment.parent) // (RTD = reply to digest)
		}

		coze = await CVA.CommentCreate(comment)
		api = Cyphrme.API.Post.Comment
	}

	// console.debug("Testing: ", coze)
	// return

	let formData = new FormData()
	formData.append('coze', JSON.stringify(coze))
	cb(await Ajax.FetchPost(api, formData))
}

/**
getCommentTextDigest accepts a comment's ID and returns the (B64) digest of the
comment's current text on the page. The hash alg used for the digest is
dependent on the current selected CozeKey.
@param   {B64}  id
@returns {Dig}
@throws  {error}  Fails when element does not exist.
*/
async function getCommentTextDigest(id) {
	let commentElem = document.getElementById(id)
	if (commentElem === null) {
		Cyphrme.Error("Could not find comment for ID: " + id) // throws
	}
	return Lib.HashAB(await Lib.SToArrayBuffer(commentElem.querySelector('.comment').textContent), Login.CozeKey.alg)
}

/**
returnReplyCallback expects an object that contains comment reply or comment
edit. Edit = true means the comment updates an existing comment/review. Edit =
false means the comment is a reply and a new comment is added to the page.
Server response holds `edit` to know if it was a reply or edit.
@param   {object}  parsd   Parsed server response.
@returns {void}
*/
function returnReplyCallback(parsd) {
	let comment = parsd.obj.cmt
	let edit = parsd.obj.edit
	let parent = Element
	if (!isTrue(edit)) {
		// Reply
		if (!isEmpty(Login.AccountDetails.display_name)) {
			comment.display_name = Login.AccountDetails.display_name
		}
		parent = document.querySelector("div[data-commentid=" + "\"" + comment.parent + "\"")
		let card = createComment(comment)
		parent.append(card)
	} else {
		// Edit
		parent = document.querySelector("div[data-commentid=" + "\"" + comment.id + "\"")
		parent.querySelector('.comment').innerText = comment.text
		parent.querySelector('.updated').innerText = new Date(comment.updated).toLocaleString()

		// Clear and set review fields.
		let ce = parent.querySelector('.comment_extra')
		ce.innerHTML = ""
		createExtraAppend(ce, "Rating", comment.rating)
		createExtraAppend(ce, "Purchased", isBool(comment.purchased))
		createExtraAppend(ce, "Purchase Location", comment.purchase_location)
		createExtraAppend(ce, "Counterfeit", comment.counterfeit_report)
	}

	// Removes existing reply div on success.
	parent.querySelector(".replyDiv").remove()
	// Hide No Comments incase shown.
	Hide('noCommentsOnPage')
}

/**
SubmitReview Submits a review on a page/product, and updates the product page
with the new review's rating calculated in. This function works for any page
using the comment/review template.
@returns {void}
 */
async function SubmitReview() {
	let pay = await setReviewPay(document.querySelector('.submitReview'))
	let formData = new FormData()
	formData.append('coze', JSON.stringify(await CVA.CommentCreate(pay)))
	// formData.append('error', "true") // Debugging / testing
	let parsd = await Ajax.FetchPost(Cyphrme.API.Post.Comment, formData)
	// console.debug("Parsd: ", parsd)

	// Start of "callback".
	let commentThread = document.querySelector('#CommentSection')
	if (!isEmpty(parsd.obj) && !isEmpty(parsd.obj.cmt)) {
		Hide("reviewFormDiv")

		if (!isEmpty(Login.AccountDetails.display_name)) {
			parsd.obj.cmt.display_name = Login.AccountDetails.display_name
		}

		let card = createReview(parsd.obj.cmt)
		commentThread.append(card)
		Show(commentThread)
		Cyphrme.JumpToAnchor("#" + card.dataset.commentid)
		Hide('noCommentsOnPage')
	}
}


/**
setReviewPay returns a populated pay object, with review fields set based on the
reviewObj passed in. Other fields, such as child_ac is set from the PagRec
global.
@param   {element}   reviewDiv  HTML element holding the review's fields/values.
@param   {object}    [pay]      Optional comment pay.
@returns {Pay}
 */
async function setReviewPay(reviewDiv, pay) {
	// console.debug("Review Div: ", reviewDiv)

	// * TODO Use urlformjs to fetch fields.
	// console.debug(URLForm.GetForm(initedFormOptions))
	let reviewObj = {
		text: reviewDiv.querySelector('.reviewComment').value,
		counterfeit: reviewDiv.querySelector('.reviewReportCounterfeit').checked,
		purchase_location: reviewDiv.querySelector('.reviewPurchaseLocation').value,
		purchased: reviewDiv.querySelector('.reviewPurchased').checked,
		rating: reviewDiv.querySelectorAll('.reviewStars .bi-star-fill').length,
	}
	// console.debug("Review Object: ", reviewObj)

	// Create review, sorted by UTF-8.
	// NOTE, if fields are built out of order, the message is sent to the server
	// in the order it is built in, and the signature does not not verify.
	if (isEmpty(pay)) {
		/**@type {Comment} */
		pay = {}
	}
	if (!isEmpty(reviewObj.text)) {
		pay.text = reviewObj.text
	}
	if (!isEmpty(reviewObj.counterfeit)) {
		pay.counterfeit_report = reviewObj.counterfeit
	}
	// console.debug(PR)
	pay.root = PR.pag.subject_value
	if (!isEmpty(reviewObj.purchased)) {
		pay.purchased = reviewObj.purchased
	}
	if (!isEmpty(reviewObj.purchase_location)) {
		pay.purchase_location = reviewObj.purchase_location
	}
	if (!isEmpty(reviewObj.rating)) {
		pay.rating = parseFloat(reviewObj.rating)
		pay.rating_denom = 5
	}
	if (!isEmpty(PR.pag.child_ac)) {
		pay.child_ac = PR.pag.child_ac
	}
	return pay
}

/**
commentDelete is for deleting comments and reviews.

A div of the review/comment being deleted is passed in, and then sent as a
form to the server to be deleted.

Removes the review/comment from the page if delete is successful.
@param   {Element} commentElem   HTML Div of the entire review/comment being deleted.
@returns {void}
 */
async function commentDelete(commentElem) {
	let formData = new FormData()
	formData.append('coze', JSON.stringify(await CVA.CommentDelete(commentElem.dataset.commentid)))
	// formData.append('error', "true") // Testing / debugging
	let parsd = await Ajax.FetchPost(Cyphrme.API.Post.CommentDelete, formData)
	if (isEmpty(parsd)) {
		return
	}

	let parent = document.querySelector("div[data-commentid=" + "\"" + parsd.obj.id + "\"")
	if (isEmpty(parsd.obj.cmt)) {
		parent.remove()
	} else {
		// Mark as deleted
		parent.classList.add('border-warning')

		parent.querySelector('.text-muted').remove()
		parent.querySelector('.comment').remove()
		parent.querySelector('.buttons').remove()

		let reviewDetails = parent.querySelector('.row')
		if (!isEmpty(reviewDetails)) {
			reviewDetails.remove()
		}
		parent.prepend("Comment has been deleted")
	}

	if (document.querySelector('#CommentSection').querySelector('.comment') == null) {
		console.debug("#CommentSection is empty.  Hiding.")
		Hide(document.querySelector('#CommentSection'))
	}

	ToggleReviewForm(PR.rec, parsd.obj.id)
}

/**
ToggleReviewForm checks if any of the given record's uad matches the current
Login.UAD, or current Application CozeKey tmb. If any record is a match, the
review form div is hidden, as to not allow multiple review submissions by a
single user (from the GUI). Checks for non-authed users, and does not show if
they are not logged in.
@param   {Comment[]}  records
@param   {B64}        [id]     Optional ID for skipping over.
@returns {void}
 */
function ToggleReviewForm(records, id) {
	let hide = false
	if (!isEmpty(records)) {
		for (let i = 0; i < records.length; i++) {
			if (records[i].id == id) { // for deletes
				continue
			}
			let uad = records[i].uad
			if (uad == Login.UAD || uad == Login.CozeKey.tmb) {
				hide = true
			}
		}
	}
	if (!hide) {
		// If user is not logged in, display email invite form.
		if (isEmpty(Login.UAD)) {
			Login.CreatePreInviteEmailForm()
		} else {
			CreateSubmitReviewForm()
		}
	}
}

////////////////////////////////////////////////////////////////////////////////
// Star Section
////////////////////////////////////////////////////////////////////////////////

/**
SetRatingStar sets the review form submission rating and HTML star elements
in the GUI.
@param   {number} rating  Rating value.
@returns {void}
 */
function SetRatingStar(rating, stars) {
	for (var i = 0; i < stars.length; i++) {
		if (i < rating) {
			stars[i].innerHTML = '<i class="bi bi-star-fill"></i>'
		} else {
			stars[i].innerHTML = '<i class="bi bi-star"></i>'
		}
	}
}

/**
SetPageRatingStar sets the main rating of the page.
@param   {number} rating  Rating value
@returns {void}
 */
async function SetPageRatingStar(rating) {
	// Sanitize NaN
	if (isEmpty(rating)) {
		rating = 0
	}
	// console.debug(rating)
	/** @property {Element}   stars     - The rating star div. */
	let stars = document.querySelector('.cyphrStars')

	// Stars not supported on some ACs (e.g. markdown).
	if (stars === null) {
		return
	}

	if (rating == 0) {
		Hide(stars)
		return
	}
	Show(stars)

	// Add to the page
	stars.innerHTML = getStarsFromRating(rating)
	return
}

/**
getStarsFromRating returns HTML with stars populated from the given rating.
@param   {number}       rating  Rating value
@returns {HTMLElement}
 */
function getStarsFromRating(rating) {
	// Rounds anything under .75 and over .25 to to .5, everything else to nearest integer.
	rating = (Math.round(rating * 2) / 2).toFixed(1)
	var starhtml = ""
	var starNum = 0

	for (var i = .5; i <= rating; i += .5) {
		if (i % 1 == 0) {
			// data-rating is for user submittable ratings, and currently, half stars
			// are not supported for submitting ratings.
			starhtml += '<span class="star" data-rating=' + i + '><i class="bi bi-star-fill"></i></span>'
			starNum++
		} else if (i == rating) {
			starhtml += '<i class="bi bi-star-half"></i>'
			starNum++
		}
	}
	while (starNum < 5) {
		let dataRating = starNum + 1 // Don't do within string, concats 1 as a string.
		starhtml += '<span class="star" data-rating=' + dataRating + '><i class="bi bi-star"></i></span>'
		starNum++
	}
	return starhtml
}