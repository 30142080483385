"use strict"

// This module currently only handles what is referred to throughout the app as
// the `AC Attributes Options Form`, and not the `AC Print Options Form`.
// ac_gen.js handles the print options form.
// Until URLFormJS can handle multiple forms on the page, this module handles
// blacklisting the form parameters from the print options form, as to not allow
// any of the printing options in the signed payload.

//// How to add more pre-defined options to the options form:
// - Add the option in the `ac_options.tmpl`.
// - Add the option to the URLFormJS FormParameters.
// - Add check in `ACRegenPay`.
// - Add check in `ProcessOpts`.
// - Add check in `specialReserved` in `ac.js`.
// - Add field to AC in Go.

import * as Cyphrme from './cyphrme.js'
import * as Login from './login.js'
import * as CVA from './cva.js'
import * as Ajax from './ajax.js'

import * as Coze from '../pkg/coze_all~fv=-7uCIHNa.min.js'
import * as Lib from '../lib/lib~fv=LNbMt1n5.min.js'
import '../pkg/urlform~fv=VhWyOSvq.min.js' // Namespaced as 'URLForm'.

export {
	ACFormOptions,
	AcsFromTree,
	ACGetOptions,
	ACGetOptionsExtra,
	ACGetBlacklistedPrintOptions, // TODO deprecate
	ACRegenPay,
	InitACOptions,
	CheckModelParent,
	InitSubmitOptions,
	SetOriginalAC,
	ProcessOpts,
	RecalcOptionGUI,
	GetStickerTitle,
}

/**
@typedef {import('../../../pkg/cozejs/typedef.js').B64}                 B64
@typedef {import('../../../pkg/cozejs/typedef.js').Pay}                 Pay
@typedef {import('../../../pkg/cozejs/typedef.js').Can}                 Can
@typedef {import('../../../pkg/urlformjs/urlform.js').FormParameter}    FormParameter
@typedef {import('../../../pkg/urlformjs/urlform.js').FormParameters}   FormParameters
@typedef {import('../../../pkg/urlformjs/urlform.js').FormOptions}      FormOptions
@typedef {import('../lib/typedef.js').ACOptions}                        ACOptions
@typedef {import('../lib/typedef.js').PJArgs}                           PJArgs
@typedef {import('./label.js').CustomPrintParams}                       CustomPrintParams
*/

/**
ACNormal holds the coze normal components, for an AC, as a JSON object.

- need: Required coze.Pay fields.
- optional: Optional coze.Pay fields.
- extra: Extra coze.Pay fields (additional from required).
- normal: AC's normal/canonical fields for coze.Pay.
- pay: AC's signed payload from all other fields.
@typedef  {object}     ACNormal
@property {object}     need
@property {object}     optional
@property {object}     extra
@property {Can}        normal
@property {Pay}        pay
*/


/**
ACFormOptions is used on many pages, so the definition is in /app instead of
/page.  
@type {FormOptions} 
*/
const ACFormOptions = {
	"id": "#OptionsForm",
	"prefix": 'input_', // TODO deprecate input_, old old old, don't need this any more.  
	"FormParameters": [{
			"name": "is_model",
			"type": "bool",
			"funcTrue": () => ToggleVisible('input_is_model_true'),
		},
		{
			"name": "model_name",
		},
		{
			"name": "short_name",
		},
		{
			"name": "has_model",
			"type": "bool",
			"funcTrue": () => ToggleVisible('input_model_parent_true'),
		},
		{
			"name": "model_parent",
		},
		{
			"name": "has_title",
			"type": "bool",
			"funcTrue": () => ToggleVisible('input_has_title_true'),
		},
		{
			"name": "title",
		},
		{
			"name": "is_markdown",
			"type": "bool",
			"funcTrue": () => ToggleVisible('input_is_markdown_true'),
		},
		{
			"name": "markdown_dig",
		},
		{
			"name": "is_counterfeit",
			"type": "bool",
		},
		{
			"name": "is_stolen",
			"type": "bool",
		},
		// Blacklist / Whitelist options
		// Users have to refresh the page on updates to blacklist/whitelist options.
		{
			"name": "lock_comments",
			"type": "bool",
		},
		{
			"name": "disable_comments",
			"type": "bool",
		},
		{
			"name": "disable_authentic",
			"type": "bool",
		},
		{
			"name": "disable_images",
			"type": "bool",
		},
		{
			"name": "disable_files",
			"type": "bool",
		},
		{
			"name": "disable_json_details",
			"type": "bool",
		},
		{
			"name": "disable_brand_logo",
			"type": "bool",
			"funcTrue": () => ToggleVisibleIfExists('hide_brand_logo'),
		},
		{
			"name": "brand_logo",
		},
		// Cyphrme logo may be used as a special case in the future, and always sent
		// from the server.
		{
			"name": "disable_cyphrme_logo",
			"type": "bool",
		},
		{
			"name": "extra_options_json"
		},
		{ // Must go after other options.
			"name": "highlight",
			"type": "bool",
			"funcTrue": async () => {
				document.querySelector("#printHighlightCSS").disabled = false
			}
		},
	],
}

// AC Form params that are used for checks, counters, and other various
// application uses, that are not signed themselves, but for determining other
// fields to be signed.
const BLACKLIST_ACApplicationFormParameters = [
	"isSerial",
	"serialStart",
]

// Custom Print Form Options Form Parameters.
// TODO Deprecate now that URLFormJS can handle multiple forms on a page. After
// deprecating, GetACOptions should be as simple as returning Form.GetForm()
const BLACKLIST_ACPrintOptionFormParameters = [
	"pageHeight",
	"pageWidth",
	"pageMarginTop",
	"pageMarginBottom",
	"pageMarginTopPaddingTop",
	"pageMarginBottomPaddingTop",
	"pageInnerPaddingLeft",
	"startAtLabel",
	"pageLandscape",
	"highlight",
	"outline",
	"noHeader",
	"blankHeader",
	"labelHeight",
	"labelWidth",
	"labelAuthTitleHeight",
	"labelAuthTitlePaddingTop",
	"labelMarginLeft",
	"labelContentsPaddingTop",
	"labelContentsMarginLeft",
	"labelLogoHeight",
	"labelTitleLogoHeight",
	"labelInnerPaddingTop",
	"labelInnerPaddingLeft",
	"labelQRSize",
	"labelVertical",
	"labelRotate90",
	"labelTitleLogo",
	"labelTitleLogoImageLink",
	"labelBrandingLevel",
	"labelCustomLogo",
	"labelCustomLogoImageLink",
	"labelTemplate",
	// Extra print options not in main custom print options form.
	"getModelLabelDescription",
	"labelDescription",
]


// TODO Deprecate now that URLFormJS can handle multiple forms on a page. After
// deprecating, GetACOptions should be as simple as returning Form.GetForm()
//
// AC Print Options FormParameters defined in ac_gen.js are blacklisted from
// this file, also referred to as the AC Attributes Options Form.
const BLACKLIST_ACFormParameters = [
	"advanced",
	"numberOfACsInput",
	"uploadBatchSize",
	"genTimeout",
	"urlInput",
	"generateQR",
	"resignIats",
	// Batch
	"isPJ",
	"DeterministicBatch",
	"deterministicSeed",
	"batchPages",
	"batchDepthSizes",
	"count",
	...BLACKLIST_ACPrintOptionFormParameters
]

/** Holds the current Coze Normal state of the AC.
@type {ACNormal} 
*/
var ACNormal = {}

/**Holds the form's keys. */
var formKeys = []

////////////////////// !!  Important  !! ///////////////////////////////////////
////
// InitSubmitOptions must be called in order to set the SubmitOptions
// callback, as well as set the event listener for the update options button to
// call the SubmitOptions Function that was set.

// updateButton is the button for updating something from the options form
const updateButton = document.querySelector('#OptionUpdateBtn')

//// Defaults
// Default function that logs an error message if no SubmitOptionsFunction
// is set.
var submitOptionsCallback = () => console.error("Must set a callback")

// This file is for modifying one AC at a time. This is the AC that is set
// before being modified. For variadic, SetOriginalAC must be called for each
// AC being modified.
var originalAC = {}

// Modules are read-only, so we must set the variables here, with a setter.
function InitSubmitOptions(SubmitOptionsCallback) {
	submitOptionsCallback = SubmitOptionsCallback
}

// Keep setting originalAC separate from setting the submitOptionsCallback,
// to provide variadic capability. The callback should only be set once, but
// the ac can be set many times.
function SetOriginalAC(originalac) {
	originalAC = originalac
}

// Initialized FormOptions for AC. Must be given on InitACOptions.
var initedFormOptions

/**
Onload/Initialization function for the ac options form. Optional parameters for
not initializing the show options form button, and the update button for the
options form. If these are passed in as true, the module importing this module
needs to do custom logic for the buttons and behavior.
@param {FormOptions} formOptions
@param {boolean} skipUpdateBtn=false // TODO it would be better if this option was handled by this function.  
@param {boolean} skipShowOptsBtn=false
 */
async function InitACOptions(formOptions, skipUpdateBtn) {
	// console.debug(skipUpdateBtn)

	initedFormOptions = formOptions

	// Sanitize for undefined, etc.
	if (isEmpty(skipUpdateBtn)) {
		skipUpdateBtn = false
	}
	// Extra form parameters 
	for (let i of formOptions.FormParameters) {
		formKeys.push(i.name)
	}
	// Sets update button globally for page load, for owner to be able to access
	// this wherever necessary on the page.
	if (!skipUpdateBtn) {
		Show(updateButton)
		// Adds the event listener for when the update options button is clicked.
		updateButton.addEventListener('click', async (event) => {
			event.preventDefault()
			// Submit update AC Options action.
			// Throws an error if they AccountID does not own the AC
			if (Login.UAD != originalAC.uad) {
				Cyphrme.Error('error: only the owner can update the Anti-Counterfeit')
			}

			// Set standard Pay
			let standard = {
				alg: originalAC.coze.pay.alg,
				iat: originalAC.coze.pay.iat,
				tmb: originalAC.coze.pay.tmb,
				typ: originalAC.coze.pay.typ,
				id: originalAC.coze.pay.id
			}

			//// Debugging
			// console.debug(await CVA.ACUpdate(standard)))
			// return

			let formData = new(FormData)
			formData.append('coze', JSON.stringify(await CVA.ACUpdate(standard)))
			submitOptionsCallback(await Ajax.FetchPost(Cyphrme.API.Post.ACPageUpdate, formData))
		})
	}

	// Toggle Options form checkbox element inputs.
	document.getElementById('input_disable_brand_logo').addEventListener('click', () => ToggleVisible('input_brand_logo_true'))
	document.getElementById('input_is_model').addEventListener('click', () => ToggleVisible('input_is_model_true'))
	document.getElementById('input_has_model').addEventListener('click', () => ToggleVisible('input_model_parent_true'))
	document.getElementById('input_has_title').addEventListener('click', () => ToggleVisible('input_has_title_true'))
	//document.getElementById('input_is_markdown').addEventListener('click', () => ToggleVisible('input_is_markdown_true'))
	document.getElementById('starter_pack').addEventListener('click', () => ToggleVisible('starter_pack_true'))

	// Toggle AC Options form

	// Checks whether or not the model_parent meets very basic requirements before
	// allowing the user to set this.
	document.querySelector('#input_model_parent').addEventListener('input', () => {
		let modelID = document.querySelector('#input_model_parent').value
		if (!Cyphrme.IsCyphrmeDigest(modelID)) {
			updateButton.disabled = true
			Cyphrme.Notification("The Model ID is not correct.", "error")
			return
		}
		if (modelID == originalAC.id) {
			updateButton.disabled = true
			Cyphrme.Notification("You cannot make the Model ID the same as the current ID.", "error")
			return
		}
		updateButton.disabled = false
	})

	document.querySelector('#input_brand_logo').addEventListener('input', function () {
		if (this.value !== "" && !Cyphrme.IsCyphrmeDigest(this.value)) {
			updateButton.disabled = true
			Cyphrme.Notification("The brand logo digest is not a valid digest.", "error")
			return
		}
		updateButton.disabled = false
	})

	// Recalculate Extra fields once free form area is valid JSON.
	document.getElementById('input_extra_options_json').addEventListener('input', async (item) => {
		let validJSON = true
		if (!isEmpty(item.target.value)) {
			try {
				var parsd = JSON.parse(item.target.value)
			} catch (error) {
				validJSON = false
			}
			if (!validJSON) {
				Show('freeFormValidJSONWarning')
				return
			}
			ACNormal.extra = parsd
		} else {
			ACNormal.extra = {}
		}
		Hide('freeFormValidJSONWarning')
		RecalcOptionGUI(await recalculatePayFromACNormal())
	})

	// Reprocess AC Options on change
	document.getElementById('OptionsForm').addEventListener('input', async () => {
		try {
			var newAC = await ProcessOpts(ACNormal, await ACGetOptionsExtra())
			// console.debug(newAC)
		} catch (error) {
			console.error(error)
			return
		}
		RecalcOptionGUI(newAC.pay)
	})

}


/**
AcsFromTree returns an object of populated Anti-Counterfeits, from the given
tree. Each AC contains it's page and batch information from the tree.
@param   {PJArgs}  pjArgs
@returns {ACsObj}
@throws  {error}
*/
async function AcsFromTree(pjArgs) {
	// console.debug(pjArgs)
	if (isEmpty(pjArgs.tree.leavesID)) {
		throw new Error('AcsFromTree: Tree.leavesID is empty. (pathCalc must be true on tree generation for leavesID to be set.)')
	}

	let acs = {}
	for (let id of pjArgs.tree.leavesID) {
		let ac = await Lib.NewAnticounterfeit(Login.CozeKey, {
			id: id // Don't gen ac with random ID.
		})
		ac = await ProcessOpts(ac, pjArgs.ac_options)
		// console.debug(ac)
		ac.qr_uri = Cyphrme.QRCodeURL
		ac.base37 = Lib.BASE37Padded(await Lib.B64ToHex(ac.pay.id), Lib.AB.Base16, ac.pay.alg)
		ac.pj_id = pjArgs.id // pj_id is outside pay, and never signed.  (AC's are associated to job via ticket in the database, but the field isn't signed)
		acs[id] = ac
	}
	return acs
}


/**
Takes a model parent/ model ID and returns whether or not the model seems
likely to be a valid model ID, and is not the same as the child's ID.
@param   {B64}     id    ID of the model parent.
@returns {boolean}
 */
function CheckModelParent(id) {
	if (!Cyphrme.IsCyphrmeDigest(id)) {
		// console.debug("The Model ID is not correct.")
		return false
	}
	if (id == originalAC.id) {
		// console.debug("You cannot make the Model ID the same as the current ID.")
		return false
	}
	return true
}

/**
ACGetOptions returns ACOptions from the ac options form.
This does not include Extra fields. If needing the full AC Options form,
including extra fields, use ACGetOptionsExtra() instead.

'URLFormJS' must be initialized (outside of this module) to call this function.

'extra_options_json' is used in the options form for holding extra fields,
but excluded from the ACOptions returned. To get extra fields, use
ACGetOptionsExtra().
@returns {ACOptions}
@throws  {error}
*/
function ACGetOptions() {
	// TODO namespace for ac in HTML
	let obj = URLForm.GetForm(initedFormOptions) // throws
	let acOptions = {}
	for (let key in obj) {
		if (BLACKLIST_ACFormParameters.includes(key) || key === 'extra_options_json') {
			continue
		}
		acOptions[key] = obj[key]
	}
	return acOptions
}

/**
ACGetBlacklistedPrintOptions returns only the blacklisted ac print options that
are elided from `ACGetOptions` and `ACGetOptionsExtra`. Blacklisted AC print
options are only signed when the selected prefined label size is `Custom`. To
be able to recreate the sticker, all of the print options are signed.

TODO Split AC Print Options out into a seperate form that can just be called
with GetForm to avoid the blacklists.
@returns {CustomPrintParams}
@throws  {error}
*/
function ACGetBlacklistedPrintOptions() {
	let obj = URLForm.GetForm(initedFormOptions) // throws
	let printOpts = {}
	for (let key in obj) {
		if (BLACKLIST_ACPrintOptionFormParameters.includes(key)) {
			printOpts[key] = obj[key]
			continue
		}
	}
	return printOpts
}

/**
ACGetOptions returns a parsed ACOptions object from the full AC options form,
including extra fields.
@returns {ACOptions}
@throws  {error}
 */
function ACGetOptionsExtra() {
	let optional = ACGetOptions() // throws
	let ex = document.getElementById('input_extra_options_json').value
	if (!isEmpty(ex)) {
		let extra = JSON.parse(ex)
		for (let v in extra) {
			if (isEmpty(optional[v])) {
				optional[v] = extra[v]
			}
		}
	}
	return optional
}

/**
ACRegenPay accepts an old pay, and a parsed Options form. The fields in the
parsed form are added to a copy of the old pay passed in, and the new pay are
returned after adding the parsed fields.
@param   {Pay}       oldPay    Old/current Coze Pay.
@param   {ACOptions} opts      Parsed Options form.
@param   {string}    [typ]     Optional typ. Defaults to "cyphr.me/ac/create"
@returns {Pay}
 */
async function ACRegenPay(oldPay, opts, typ) {
	// console.debug("OldPay: ", oldPay, "Opts: ", opts, "Typ:", typ)
	if (isEmpty(typ)) {
		typ = CVA.Typs.TypACCreate
	}
	let pay = {
		alg: oldPay.alg,
		iat: oldPay.iat,
		tmb: oldPay.tmb,
		typ: typ,
		id: oldPay.id
	}

	// Set new options

	// See `ProcessOpts` docs on `isSerial`.
	if (opts.isSerial) {
		pay.serial = opts.serial
	}

	if (opts.has_model) {
		if (Cyphrme.IsBASE37(opts.model_parent)) {
			opts.model_parent = await Lib.HexTob64ut(await Lib.HexPadded(opts.model_parent, Lib.AB.BASE37, Login.CozeKey.alg))
		}
		pay.has_model = opts.has_model
		pay.model_parent = opts.model_parent
	}
	if (opts.is_model) {
		pay.is_model = opts.is_model
		pay.model_name = opts.model_name
	}
	if (opts.has_title) {
		pay.title = opts.title
	}
	if (opts.is_markdown) {
		if (isEmpty(opts.markdown_dig)) {
			throw new Error("markdown digest must be populated if ac is a markdown page")
		}
		pay.is_markdown = true
		pay.markdown_dig = opts.markdown_dig
	} else {
		delete pay.is_markdown
		delete pay.markdown_dig
	}
	if (opts.is_counterfeit) {
		pay.is_counterfeit = opts.is_counterfeit
	}
	if (opts.is_stolen) {
		pay.is_stolen = opts.is_stolen
	}

	// AC Blacklist fields
	if (opts.lock_comments) {
		pay.lock_comments = opts.lock_comments
	}
	if (opts.disable_comments) {
		pay.disable_comments = opts.disable_comments
	}
	if (opts.disable_authentic) {
		pay.disable_authentic = opts.disable_authentic
	}
	if (opts.disable_images) {
		pay.disable_images = opts.disable_images
	}
	if (opts.disable_files) {
		pay.disable_files = opts.disable_files
	}
	if (opts.disable_json_details) {
		pay.disable_json_details = opts.disable_json_details
	}
	if (opts.disable_brand_logo) {
		pay.disable_brand_logo = opts.disable_brand_logo
		delete pay.brand_logo
	} else {
		delete pay.disable_brand_logo
	}
	if ("brand_logo" in opts && (!opts.disable_brand_logo)) {
		pay.brand_logo = opts.brand_logo
	}
	if (opts.disable_cyphrme_logo) {
		pay.disable_cyphrme_logo = opts.disable_cyphrme_logo
	}

	// Add extra fields
	for (let key in opts) {
		if (!formKeys.includes(key)) {
			pay[key] = opts[key]
		}
	}

	return pay
}

/**
TODO Run through exclusions for when one type is checked, any excluded options
get disabled.

ProcessOpts processes over the given AC Options Form Object, and add any of the
fields (if applicable) to the given ac, and return the newly modified ac,
resigned. If the ac has not been modified, it is not resigned, and the original
is returned.

Else if statements are used for removing items that may have been
removed/deleted from the object from user modifications. Returns new AC, being
resigned if updates occurred.

Coze does not allow resigning of an AC if the tmb from the current AC does not
match the Coze Key signing the current payload. The logic below sets the
payloads tmb to the current Coze Key, if the original AC's tmb is different and
either the `uad` of the AC also owns the current Coze Key.
@param   {Coze}      ac     Coze object.
@param   {ACOptions} opts   Parsed from the AC options form.
@returns {ac}
 */
async function ProcessOpts(ac, opts) {
	// console.debug("AC: ", ac, "Options: ", opts)
	if (isEmpty(opts)) {
		opts = {}
	}
	// Initialize counter `serial` for serialization when`opts` first given.
	if ("isSerial" in opts && !("serial" in opts)) {
		if (!("serialStart" in opts)) {
			opts.serialStart = 0
		}
		opts.serial = opts.serialStart
	}
	var acNotGenerated = false
	if (isEmpty(ac.pay) || isEmpty(ac.pay.id)) {
		acNotGenerated = true
		ac.pay = {}
	}

	// Resign ACs with current logged in key, instead of original key that signed,
	// if owner.
	if (!acNotGenerated && ac.pay.tmb !== Login.CozeKey.tmb) {
		if (!isEmpty(originalAC) && Login.UAD === originalAC.uad) {
			console.debug(`Original 'tmb' is different from current 'tmb', using current 'tmb'.`)
			ac.pay.tmb = Login.CozeKey.tmb
		} else {
			console.error('Must be owner to update the AC.')
			return
		}
	}

	//// Options
	// if (!isEmpty(opts)) {
	var resign = false

	// AC Print Opts

	// NOTE: Current convention is for AC Options to be lower snake cased, while
	// AC Print Options are lower camel cased. Most AC Print Options are not
	// signed, except for on a model, or PJ. There may be exceptions, such as
	// `serial` on individual ACs. Incrementers on opts must be incremented after
	// signing, or the incremented value is what gets signed. Seems JS has a race
	// condition when using the same incrementer reference.
	if (opts.isSerial) {
		ac.pay.serial = opts.serial
		resign = true
	} else if (!isEmpty(ac.pay.serial)) {
		delete ac.pay.serial
	}

	// AC Opts

	if (opts.has_model) {
		if (!isEmpty(opts.model_parent)) {
			if (!Cyphrme.IsCyphrmeDigest(opts.model_parent)) {
				Cyphrme.Error("parent is an unsupported digest")
			}
			ac.pay.model_parent = opts.model_parent
			if (Cyphrme.IsBASE37(opts.model_parent)) {
				ac.pay.model_parent = await Lib.HexTob64ut(await Lib.HexPadded(opts.model_parent, Lib.AB.BASE37, Login.CozeKey.alg))
			}
			resign = true
		}
	} else if (!isEmpty(ac.pay.model_parent)) {
		delete ac.pay.model_parent
	}

	if (opts.is_model) {
		ac.pay.is_model = true
		if (!isEmpty(opts.model_name)) {
			ac.pay.model_name = opts.model_name
		} else if (!isEmpty(ac.pay.model_name)) {
			delete ac.pay.model_name
		}

		if (!isEmpty(opts.short_name)) {
			ac.pay.short_name = opts.short_name
		} else if (!isEmpty(ac.pay.short_name)) {
			delete ac.pay.short_name
		}

		resign = true
	} else if (!isEmpty(ac.pay.is_model)) {
		delete ac.pay.is_model
		delete ac.pay.short_name
		delete ac.pay.model_name
	}

	if (opts.is_markdown && !isEmpty(opts.markdown_dig)) {
		if (!Cyphrme.IsCyphrmeDigest(opts.markdown_dig)) {
			Cyphrme.Error("markdown digest is an unsupported digest")
		}
		ac.pay.markdown_dig = opts.markdown_dig
		ac.pay.is_markdown = true
		resign = true
	} else if (!isEmpty(ac.pay.markdown_dig)) {
		delete ac.pay.markdown_dig
		delete ac.pay.is_markdown
	}
	if (opts.has_title && !isEmpty(opts.title)) {
		ac.pay.title = opts.title
		resign = true
	} else if (!isEmpty(ac.pay.title)) {
		delete ac.pay.title
	}
	if (opts.is_counterfeit) {
		ac.pay.is_counterfeit = opts.is_counterfeit
		resign = true
	} else if (!isEmpty(ac.pay.is_counterfeit)) {
		delete ac.pay.is_counterfeit
	}
	if (opts.is_stolen) {
		ac.pay.is_stolen = opts.is_stolen
		resign = true
	} else if (!isEmpty(ac.pay.is_stolen)) {
		delete ac.pay.is_stolen
	}

	// AC Blacklist values
	if (opts.lock_comments) {
		ac.pay.lock_comments = opts.lock_comments
		resign = true
	} else if (!isEmpty(ac.pay.lock_comments)) {
		delete ac.pay.lock_comments
	}
	if (opts.disable_comments) {
		ac.pay.disable_comments = opts.disable_comments
		resign = true
	} else if (!isEmpty(ac.pay.disable_comments)) {
		delete ac.pay.disable_comments
	}
	if (opts.disable_authentic) {
		ac.pay.disable_authentic = opts.disable_authentic
		resign = true
	} else if (!isEmpty(ac.pay.disable_authentic)) {
		delete ac.pay.disable_authentic
	}
	if (opts.disable_images) {
		ac.pay.disable_images = opts.disable_images
		resign = true
	} else if (!isEmpty(ac.pay.disable_images)) {
		delete ac.pay.disable_images
	}
	if (opts.disable_files) {
		ac.pay.disable_files = opts.disable_files
		resign = true
	} else if (!isEmpty(ac.pay.disable_files)) {
		delete ac.pay.disable_files
	}
	if (opts.disable_json_details) {
		ac.pay.disable_json_details = opts.disable_json_details
		resign = true
	} else if (!isEmpty(ac.pay.disable_json_details)) {
		delete ac.pay.disable_json_details
	}
	if (opts.disable_brand_logo) {
		ac.pay.disable_brand_logo = opts.disable_brand_logo
		delete ac.pay.brand_logo
		resign = true
	} else if (!isEmpty(ac.pay.disable_brand_logo)) {
		delete ac.pay.disable_brand_logo
	}
	if ("brand_logo" in opts && !opts.disable_brand_logo) {
		ac.pay.brand_logo = opts.brand_logo
		resign = true
	}

	if (opts.disable_cyphrme_logo) {
		ac.pay.disable_cyphrme_logo = opts.disable_cyphrme_logo
		resign = true
	} else if (!isEmpty(ac.pay.disable_cyphrme_logo)) {
		delete ac.pay.disable_cyphrme_logo
	}


	//// Extras
	let currentSupportedOptions = []
	for (let p of ACFormOptions.FormParameters) {
		currentSupportedOptions.push(p.name)
	}
	// console.debug(currentSupportedOptions)
	// console.debug(opts)
	for (let param in opts) {
		if (!currentSupportedOptions.includes(param) && !BLACKLIST_ACApplicationFormParameters.includes(param)) {
			ac.pay[param] = opts[param]
		}
	}

	// Resign if AC has been modified, and is generated already.
	if (resign && !acNotGenerated) {
		delete ac.pay.seed
		delete ac.pay.base37
		ac = await Coze.Sign(ac, Login.CozeKey)
	}
	// Increment after signing, so that reference to incrementer is not used.
	if (opts.isSerial && resign) {
		opts.serial++
	}
	return ac
}

/**
Recalculate the AC Pay from the current ACNormal state.
The pay is constructed in the following order:
-Need     (Required fields)
-Optional (Optional fields)
-Extra    (Extra fields)
@returns {Pay} pay   Coze Pay with AC Normalized fields.
 */
async function recalculatePayFromACNormal() {
	let pay = {
		...ACNormal.need
	}
	if (!isEmpty(ACNormal.optional)) {
		for (let v in ACNormal.optional) {
			pay[v] = ACNormal.optional[v]
		}
	}
	if (!isEmpty(ACNormal.extra)) {
		for (let v in ACNormal.extra) {
			pay[v] = ACNormal.extra[v]
		}
	}
	return pay
}

/**
Calculates (or recalculates) and sets the ACNormal object from the given pay.
The pay should be the full AC Pay.

If the given pay is empty, ACNormal and the user READ only JSON area are
reset/cleared.

Sets the AC Options form GUI from the split out components.

TODO Run through isNormal() after splitting into various components.
@param   {Pay}  pay  Coze Pay with an Anti-Counterfeit Coze Normal.
@returns {void}
 */
function RecalcOptionGUI(pay) {
	let userJSON = {}
	let optInfoElem = document.getElementById('optionsInfo')
	// AC Gen page may have no options and no AC generated yet.
	if (isEmpty(pay)) {
		ACNormal = {}
		optInfoElem.textContent = ""
		return
	}

	// Split AC into Coze Normal format.
	let normal = ["alg", "iat", "tmb", "typ", "id"]

	// Required fields
	let need = {}
	// Check if AC has been generated yet.
	if (!isEmpty(pay.id)) {
		need = {
			alg: pay.alg,
			iat: pay.iat, // AC iat, not coze iat
			tmb: pay.tmb,
			typ: pay.typ,
			id: pay.id,
		}
	}

	// Optional fields
	let optional = {}

	for (let opt of ACFormOptions.FormParameters) {
		if (opt.name in pay) {
			normal.push(opt.name)
			optional[opt.name] = pay[opt.name]
			userJSON[opt.name] = pay[opt.name]
		}
	}

	// Extra fields
	let extra = {}
	for (let v in pay) {
		if (!normal.includes(v)) {
			normal.push(v)
			extra[v] = pay[v]
			userJSON[v] = pay[v]
		}
	}

	// Set Extra sticky JSON text area for when page loads and AC has extra fields.
	if (!isEmpty(extra)) {
		document.getElementById('input_extra_options_json').value = JSON.stringify(extra, null, 2)
	}

	// Set GUI READ ONlY JSON area for displaying what optional/extra fields are
	// signed for the AC.
	if (!isEmpty(userJSON)) {
		optInfoElem.textContent = JSON.stringify(userJSON, null, 2)
	} else {
		optInfoElem.textContent = ""
	}

	// Set global state
	ACNormal = {
		need: need, // Required fields.
		optional: optional, // Optional fields.
		extra: extra, // Extra fields not specified in optional.
		normal: normal, // Coze.Normal Requirements / Canon
		pay: pay // Original Pay used to construct this Object.
	}
}

/**
Returns AC sticker title, if applicable.

Sticker titles returned have a maximum of 12 characters.

The returned title for the sticker is chosen in the following order:
Label Description -> Short Name -> Model Name -> Title -> SKU

For handling the main or child AC logic, pass in the AC, or AC's latest Coze.
If an AC has a parent, and is wanting to be used, the parent's AC or Coze
should be given, and not the child AC.
@param   {AC|Coze} ac            Anti-Counterfeit object.
@returns {string}
 */
function GetStickerTitle(ac) {
	// console.debug("AC: ", ac)
	// TODO think about: if ac.coze not empty do parent logic and recall this func with ac.coze
	let stickerTitle = ""
	let ld = document.getElementById('input_labelDescription')
	if (ld !== null && !isEmpty(ld.value)) {
		stickerTitle = ld.value
	} else if (!isEmpty(ac.pay.short_name)) {
		stickerTitle = ac.pay.short_name
	} else if (!isEmpty(ac.pay.model_name)) {
		stickerTitle = ac.pay.model_name
	} else if (!isEmpty(ac.pay.title)) {
		stickerTitle = ac.pay.title
	} else if (!isEmpty(ac.pay.sku)) {
		stickerTitle = ac.pay.sku
	}
	return stickerTitle
}