"use strict"

import * as Cyphrme from './cyphrme.js'

export {
	Fetch,
	FetchHTML,
	FetchPost,
	Batcher,
}

/**
SuccessJSON holds the Cyphr.me server response when calling any JSON API
endpoint. Success is determined from `success` and not HTTP status codes.

- success: True when request succeeded for the given endpoint. False if an error
  with the request.
- obj: Object that holds data from the given endpoint called.
- msg: Server message. Could be a success or fail message.
- paginate: Server's paginate object.
- records: Data from the given endpoint.
@typedef  {object}   SuccessJSON
@property {boolean}  success
@property {JSON}     [obj]  // only guarantee to be populated on success, but
may be populated on failure.  
@property {string}   [msg] // Only populated for pagination.  
@property {JSON}     [paginate]
@property {JSON}     [records]
*/

/**
BatcherCallback is called on a per batch basis. Optionally async.
@async
@callback            BatcherCallback  Called after dragster drop for each file.
@param     {File}    file
*/


/**
Fetch is the Cyphr.me JSON handler for getFetch().
@param   {string}        url  URI for upload.
@returns {JSON}          Promise w/ success JSON.
@throws  {error}
 */
async function Fetch(url) {
	console.log("Fetch: " + url)
	return jsonResErrorHandler(await getFetch(url))
}

/**
FetchHTML is the Cyphr.me HTML handler for getFetch().

TODO error check that returned HTML is the correct HTML, and not the
shruggy page. Implement status codes for only for HTML pages. Error HTML
pages should have a different response code.
@param   {string}         url  URI for upload.
@returns {html}           Promise w/ JSON server response.
@throws  {error}
 */
async function FetchHTML(url) {
	console.log("FetchHTML: " + url)
	let res = await getFetch(url)
	// Cyphr.me always returns 200 on success HTML, but another code if error HTML.  
	if (!res.ok) { // HTTP 200 is OK.
		console.log("FetchHTML error body", res)
		Cyphrme.Error("HTML HTTP request failed") // throws
	}
	return res.text() // Don't use res.body() which returns ReadableStream, not text. 
}

/**
FetchPost is the JSON handler for postFetch(), which is the actual fetch.
@param   {string}          url   URI for upload.
@param   {formdata}        data  FormData for upload.
@returns {JSON}         Promise w/ (parsed) JSON server response.
@throws  {error}
 */
async function FetchPost(url, data) {
	console.log("FetchPost: " + url)
	return jsonResErrorHandler(await postFetch(url, data))
}

/**
postFetch returns Promise<Response> allowing an await. Keywords: ajax. Throws
error on 400, and there appears to be nothing that can be done about that.
https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
https://stackoverflow.com/questions/40248231/how-to-handle-http-code-4xx-responses-in-fetch-api
@param   {string}            url   URI for upload.
@param   {FormData}          data  Data for upload.
@returns {Promise<Response>}       Promise w/ Fetch response.
 */
async function postFetch(url, data) {
	// console.debug("Fetch Obj: ", obj)
	// const controller = new AbortController()
	// setTimeout(() => controller.abort(), 60000) // 1 min timeout
	// For sending credentials in requests to both same-origin and cross-origin
	// calls, use: `credentials = "include"`
	// Don't set `Content-Type`, as the browser sets it, while also including
	// the `boundary` parameter. Otherwise, the multipart form is sent in the
	// body, and are not parsed by the server.
	// headers: {
	// 	'Content-Type': 'application/json'
	// },
	return fetch(url, {
		method: "POST", // *GET, POST
		mode: 'cors', // no-cors, *cors, same-origin
		cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
		credentials: 'same-origin', // include, *same-origin, omit
		redirect: 'follow', // manual, *follow, error
		referrerPolicy: 'no-referrer',
		body: data,
		// signal: controller.signal,
	})
}

/**
getFetch returns Promise<Response> allowing an await.  See Verifier's `get()`
for an example of on page error handling.  Keywords: ajax. Still throws error on
400, and there appears to be nothing that can be done about that.
https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
https://stackoverflow.com/questions/40248231/how-to-handle-http-code-4xx-responses-in-fetch-api
@param   {string}   url  URI for upload.
@returns {Response} Promise w/ Fetch response.
*/
async function getFetch(url) {
	return fetch(url, {
		method: "GET", // *GET, POST
		mode: 'cors', // no-cors, *cors, same-origin
		cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
		credentials: 'same-origin', // include, *same-origin, omit
		headers: {
			'Content-Type': 'application/json'
		},
		redirect: 'follow', // manual, *follow, error
		referrerPolicy: 'no-referrer',
	})
}

/**
jsonResErrorHandler is the main JSON error handler for GET and POST requests.
Returns the parsed JSON response from the server. The response passed in must be
a resolved Fetch Response, and not a promise.
@param   {Response}  res  Fetch response from the server.
@returns {JSON}
@throws  {error}
 */
async function jsonResErrorHandler(res) {
	console.debug(res)
	// Cyphr.me always returns 200 on JSON api, because doing switching on HTTP
	// and JSON is dumb, and handing errors on JSON means that JSON is independent
	// of transport (HTTP) which is good. If not 200, something is very wrong.
	if (!res.ok) { // HTTP 200 is OK.
		Cyphrme.Error("Unable to communicate with server. " + res.url) // throws
	}

	// Both empty `` and empty  `{}` json.join() don't result in error. On empty,
	// success checking after won't have parsd.msg, so error is empty which is bad
	// user experience.  
	try {
		var parsd = await res.json()
		console.debug(parsd)
	} catch (e) {
		Cyphrme.Error("Error parsing JSON response: " + e) // Throws
	}
	if (!(Object.keys(parsd).includes('success')) || parsd.success == false) { // Object may not include success or success may be false.  
		if (Object.keys(parsd).includes('msg') && (!isEmpty(parsd.msg))) {
			Cyphrme.Error(parsd.msg) // Throws
		} else {
			Cyphrme.Error("Server communication error. JSON call failed and response msg is empty: " + parsd) // Throws
		}
	}
	return parsd
}

/**
Batcher uploads the given values in the given batch size. The given callback is
not synchronous. Errors are only thrown if `aSink=true`, because `await` is
needed for errors to be throwable in an async function.

 TODO probably accept an object with params, batcherArgs? // TODO Jared.  
@param   {number}          batchSize   Batch size to split the given values into.
@param   {*[]}             values      Data being sent to the endpoint.
@param   {string}          endpoint    Endpoint to send the data.
@param   {BatcherCallback} [callback]  Optional callback to be executed asynchronously on successful response.
@param   {boolean}         [aSink]     (async) Runs asynchronously when true. Can't use `async` as name.
@returns {void}
@throws  {error}
 */
async function Batcher(batchSize, values, endpoint, callback, aSink) {
	// console.debug('Running batcher...')

	// Synchronous fetch uploader for batcher. (Note: The given callback is not
	// synchronous.) For asynchronous uploads, do not call this function with an
	// await.
	async function f(endpoint, formData, batchSize, callback) { // TODO remove signature if possible
		try {
			var response = await FetchPost(endpoint, formData) // throws
			// console.debug("Response: ", response)
			if (isEmpty(response)) {
				Cyphrme.Error("Something went wrong with your request.")
			}
			if (!response.success) {
				console.error("batcher: ", response.msg)
				Cyphrme.Error(response.msg)
			}
			if (!isEmpty(callback)) {
				callback(response.obj, batchSize)
			}
		} catch (error) {
			throw error // Pass up
		}
	}

	// TryCatch cannot be done here, and must be done in `f`. Functions that call
	// batcher must wrap in a TryCatch if needing to handle the errors that may be
	// thrown by `FetchPost`. `await` is needed for getting the errow thrown from
	// the function being called. e.g. `await f` vs `f` only throws an error from
	// the call with the await.
	for (let i = batchSize; i < values.length + batchSize; i += batchSize) {
		let batch = values.slice(i - batchSize, i)
		let formData = new FormData()
		// formData.append('error', "true") // For testing.
		formData.append('cozes', JSON.stringify(batch))
		if (aSink) {
			await f(endpoint, formData, batch.length, callback)
			continue
		}
		f(endpoint, formData, batch.length, callback) // Use aSink=true for synchronous behavior.
	}
}