"use strict";

// This module holds all of the Cyphr.me CVA's (cryptographically verifiable
// actions).  CVA's are cozies. 

/** 
@typedef {import('../../../pkg/cozejs/typedef.js').Coze}        Coze
@typedef {import('../../../pkg/cozejs/typedef.js').Pay}         Pay
@typedef {import('../../../pkg/cozejs/typedef.js').Canon}       Canon
@typedef {import('../../../pkg/cozejs/typedef.js').B64}         B64
@typedef {import('../../../pkg/cozejs/typedef.js').Key}         Key

@typedef {import('./login.js').Profile}           Profile
@typedef {import('./comment.js').CommentPay}      CommentPay
*/

import * as Login from './login.js';
import * as Cyphrme from './cyphrme.js';
import * as File from './file.js';
import * as ACOpts from './ac_options.js';

import * as Coze from '../pkg/coze_all~fv=-7uCIHNa.min.js'
import * as Lib from '../lib/lib~fv=LNbMt1n5.min.js';

export {
	// Supported Cyphr.me Coze `typ`s.
	Typs,

	// Helpers
	GenCoze,
	GenCozeWithMeta,
	GenPayStandard,

	// ACs
	ACUpdate,

	// PrintJobs
	PrintJobCreate,
	PrintJobUpdate,
	BundleCreate,

	// Key
	KeyUpsert,
	KeyDelete,
	KeyRevoke,
	KeyOtherRevoke,

	// User
	InviteCreate,
	InviteDelete,
	ProfileUpdate,
	ProfilePictureUpdate,
	EmailBackupCreate,
	EmailVerifyCreate,

	// Comment
	CommentCreate,
	CommentDelete,
	CommentUpdate,

	// File/Image
	FileCreate,
	FileDelete,
};

// Supported Cyphr.me Coze `typ`s.
const Typs = {
	TypACCreate: "cyphr.me/ac/create",
	TypACUpdate: "cyphr.me/ac/update",
	TypPJCreate: "cyphr.me/pj/create",
	TypPJUpdate: "cyphr.me/pj/update",
	TypBundleCreate: "cyphr.me/bundle/create",
	TypKeyUpsert: "cyphr.me/key/upsert",
	TypKeyDelete: "cyphr.me/key/delete",
	TypKeyRevoke: "cyphr.me/key/revoke",
	TypKeyOtherRevoke: "cyphr.me/key/other/revoke",
	TypUserInviteCreate: "cyphr.me/user/invite/create",
	TypUserInviteDelete: "cyphr.me/user/invite/delete",
	TypUserEmailBackupCreate: "cyphr.me/user/email/backup/create",
	TypUserEmailVerifyCreate: "cyphr.me/user/email/verify/create",
	TypUserProfileUpdate: "cyphr.me/user/profile/update",
	TypUserProfilePictureUpdate: "cyphr.me/user/profile/picture/update",
	TypCommentCreate: "cyphr.me/comment/create",
	TypCommentUpdate: "cyphr.me/comment/update",
	TypCommentDelete: "cyphr.me/comment/delete",
	TypFileCreate: "cyphr.me/file/create",
	TypFileDelete: "cyphr.me/file/delete",
}



/**
GenCoze generates a signed `coze` from `pay`.
@param   {Pay}       pay        Coze pay.
@param   {Key}       [Key]      Coze key. Default is currently used key.
@returns {Coze}
@throws  {error}
 */
async function GenCoze(pay, cozeKey) {
	if (isEmpty(cozeKey)) {
		cozeKey = Login.CozeKey;
	}
	return Coze.Sign({
			"pay": pay
		},
		cozeKey);
};

/**
GenCozeWithMeta generates a coze with `coze.czd` and `coze.cad` set.
@param   {Pay}     pay
@throws  {error}
@returns {Coze}
 */
async function GenCozeWithMeta(pay) {
	let coze = await GenCoze(pay)
	let meta = await Coze.Meta(coze, coze.pay.alg)
	coze.czd = meta.czd
	coze.cad = meta.cad
	return coze
}

/**
GenPayStandard returns a 'pay' object with 'alg', 'iat', and 'tmb' set in the
standard order. Uses login.js' coze key.
@returns {Pay}
 */
async function GenPayStandard() {
	return {
		alg: Login.CozeKey.alg,
		iat: Now(),
		tmb: Login.CozeKey.tmb,
	};
}


////////////////////////////////////////////////////////////////////////////////
// AC Cozies
////////////////////////////////////////////////////////////////////////////////

/**
ACUpdate returns an AC Update coze from the given payload, and AC Options. `pay`
must be given initialized with standard coze fields populated, and additionally,
`id`. `pay.typ` is not required and set in this function.
@param   {Pay}  standard
@returns {Coze}
@throws  {error} Fails if AC Options Form not initialized and loaded on the
page.
 */
async function ACUpdate(standard) {
	return GenCoze(await ACOpts.ACRegenPay(standard, ACOpts.ACGetOptionsExtra(), Typs.TypACUpdate))
}

////////////////////////////////////////////////////////////////////////////////
// PJ Cozes
////////////////////////////////////////////////////////////////////////////////

/**
PrintJobCreate signs a create PrintJob coze for uploading.

credits is included to 1. have an explicit acknowledgement of the cost and 2. to
make sure that there isn't a disagreement, which would be a bug, between client
and server.  

TODO we probably need a flag to denote "private" cozies and eventually encrypt private fields.  
@param   {PrintJob}  pj     Print job.
@returns {Coze}
 */
async function PrintJobCreate(pj) {
	// console.debug(pj)
	let pay = await GenPayStandard()
	pay.typ = Typs.TypPJCreate
	pay.id = pj.id
	// TODO Perhaps don't include seed. Seed doesn't need to be signed since id is
	// signed, but it needs to be transported with the Coze. Using HTTP transport
	// is not ideal, it would be better to include the field in the coze or beside
	// the coze. Seed is being signed at the moment solely for the purpose of
	// transport.
	pay.seed = pj.args.tree.seed
	pay.tree = pj.args.tree.id
	pay.credits = pj.credits
	pay.determ = pj.args.determ
	pay.branch_level_sizes = pj.args.tree.branch_level_sizes
	pay.ac_label_size = pj.args.ac_label_size
	pay.sbl = pj.args.ac_sticker_brand_level
	if (!isEmpty(pj.args.print_options)) {
		pay.print_opts = pj.args.print_options
	}
	return GenCozeWithMeta(pay)
}

/**
PrintJobUpdate signs an update PrintJob coze for uploading.
TODO probably deprecate and don't allow an PJ update.  
{"alg", "iat", "tmb", "typ", "id", "credits", "determ", "branch_level_sizes", "tree", "seed", "bundle_type"}
@param   {PrintJob}  pj     Print job.
@returns {Coze}
 */
async function PrintJobUpdate(pj) {

	let pay = await GenPayStandard()
	pay.typ = Typs.TypPJUpdate
	pay.id = pj.id
	pay.credits = pj.credits
	pay.determ = pj.determ
	pay.branch_level_sizes = pj.branch_level_sizes
	pay.tree = pj.tree.id
	pay.seed = pj.seed
	// pay.bundle_type = pj.bundle_type
	return GenCozeWithMeta(pay)
}

/**
Create signs a create Bundle coze for uploading.
Don't need `pj_id` as it is stored on the bundle ticket and pulled from db.
{"alg", "iat", "tmb", "typ", "id", "parent", "determ", "bundle_type", "n_childs", "numer", "denom"}
@param   {bundle}  bundle
@returns {Coze}
 */
async function BundleCreate(bundle) {
	let pay = await GenPayStandard()
	pay.typ = Typs.TypBundleCreate
	pay.id = bundle.id
	pay.parent = bundle.parent
	pay.determ = bundle.determ
	pay.bundle_type = bundle.bundle_type
	pay.n_childs = bundle.n_childs
	pay.numer = bundle.numer
	pay.denom = bundle.denom
	return GenCozeWithMeta(pay)
}


////////////////////////////////////////////////////////////////////////////////
// Key Cozes
////////////////////////////////////////////////////////////////////////////////

/**
KeyUpsert returns a signed key upsert Coze. Upsert typs are used for create and
edit actions.

signCozeKey is used to sign the upsert coze if given. This is currently only
used in first time login.
@param     {CozeKey} upsertCozeKey
@param     {CozeKey} [signCozeKey]
@returns   {Coze}
@protected {normal=[only]}
 */
async function KeyUpsert(upsertCozeKey, signCozeKey) {
	let ck = {
		...upsertCozeKey
	}
	// console.debug(ck);
	delete ck.d; // Sanity delete.  No private.
	let pay = await GenPayStandard();
	pay.typ = Typs.TypKeyUpsert
	pay.key = ck;
	if (upsertCozeKey.backup > 0) {
		pay.backup = upsertCozeKey.backup;
	}
	return GenCoze(pay, signCozeKey);
};

/**
KeyDelete deletes a key.
@param     {B64}    tmb         Thumbprint of key being deleted.
@returns   {Coze}
@protected {normal=[only]}
 */
async function KeyDelete(tmb) {
	return GenCoze({
		typ: Typs.TypKeyDelete,
		id: tmb
	});
};

/**
KeyRevoke generates a self revoke.
@param     {CozeKey} ck         CozeKey being revoked.
@param     {string}  [msg]      Optional message for revoke reason.
@returns   {Coze}
@protected {normal=[canon,option]}
 */
async function KeyRevoke(ck, msg) {
	let pay = {
		alg: ck.alg,
		iat: Now(),
		tmb: ck.tmb,
		typ: Typs.TypKeyRevoke,
		rvk: Now(),
	};
	if (!isEmpty(msg)) {
		pay.msg = msg;
	}

	return Coze.Sign({
			"pay": pay
		},
		ck);
};


/**
KeyOtherRevoke generates a other revoke action.  An other revoke is one key
revoking another key. Coze does not know whether or not a key is owned by an
individual, but the server does not allow this revoke transaction to happen,
unless the key signing the payload owns the key being revoked. The only check
that JS can perform before sending off the request would be making sure that the
key is in the wallet, but since it can just be a tmb only key, that is not
helpful.

Thought: In the future when auth has permissions, maybe other revokes can assign
which keys are allowed to revoke it.
@param     {B64}      tmb        Thumbprint for Coze Key being revoked.
@param     {string}   [msg]      Optional message for revoke reason.
@returns   {Coze}
@protected {normal=[canon,option]}
 */
async function KeyOtherRevoke(tmb, msg) {
	let pay = await GenPayStandard();
	pay.typ = Typs.TypKeyOtherRevoke;
	pay.rvk = Now();
	pay.id = tmb;

	if (!isEmpty(msg)) {
		pay.msg = msg;
	}
	return GenCoze(pay);
};


////////////////////////////////////////////////////////////////////////////////
// User
////////////////////////////////////////////////////////////////////////////////

/**
InviteCreate returns a signed Coze action for a user invite.
@param     {B64}    uad            User address digest.
@param     {string} displayName    Display name for the user.
@returns   {Coze}
@protected {normal=[canon,option]}
 */
async function InviteCreate(uad, displayName) {
	let pay = await GenPayStandard();
	pay.typ = Typs.TypUserInviteCreate;
	pay.id = uad;
	if (!isEmpty(displayName)) {
		pay.display_name = displayName;
	}
	return GenCoze(pay);
};

/**
InviteDelete returns a signed delete invite Coze.
@param     {B64}   id    Uad for the user invite being deleted from the system.
@returns   {Coze}
@protected {normal=[only]}
 */
async function InviteDelete(id) {
	let pay = await GenPayStandard();
	pay.typ = Typs.TypUserInviteDelete;
	pay.id = id;
	return GenCoze(pay);
};

/**
EmailBackupCreate returns a coze for requesting account backup via email.
@param     {string}  email    Email address for sending the private key to.
@returns   {Coze}
@protected {normal=[only]}
 */
async function EmailBackupCreate(email) {
	let pay = await GenPayStandard();
	pay.typ = Typs.TypUserEmailBackupCreate;
	pay.email = email;
	return GenCoze(pay);
}

/**
EmailBackupCreate returns a coze for requesting account backup via email.
@param     {string}     email    Email address for sending the private key to.
@returns   {Coze}
@protected {normal=[only]}
 */
async function EmailVerifyCreate(email) {
	let pay = await GenPayStandard();
	pay.typ = Typs.TypUserEmailVerifyCreate;
	pay.email = email;
	return GenCoze(pay);
}

/**
ProfileUpdate updates a user's profile.
The full profile should be given for updates, and not just the updated
fields. If 'id' is the only populated field in the profile object,
that clears all Profile fields (except 'id').

Errors if 'id' in the Profile object is empty, or unrecognized cyphrme hash.

API Coze Normal Requirements for `cyphr.me/user/profile/update`:
- Canon["alg", "iat", "tmb", "typ", "id"] +
- Option["display_name", "first_name", "last_name", "email", "address_1",
"address_2","phone_1", "phone_2", "city","state", "zip", "country"]
@param     {Profile} profile  Profile for the user.
@returns   {Coze}
@protected {normal=[canon,option]}
 */
async function ProfileUpdate(profile) {
	if (isEmpty(profile.id) || !Cyphrme.IsCyphrmeDigest(profile.id)) {
		Cyphrme.Error("Profile is missing 'id'");
	}
	// TODO Pass to Coze.Normal().
	let pay = await GenPayStandard();
	// Required fields 
	pay.typ = Typs.TypUserProfileUpdate;
	pay.id = profile.id;

	delete profile.id;
	delete profile.typ;

	// Optional fields
	for (let key in profile) {
		pay[key] = profile[key];
	}
	return GenCoze(pay);
};

/**
ProfilePictureUpdate
@param     {Blob} file
@returns   {Coze}
@protected {normal=[canon,option]}
 */
async function ProfilePictureUpdate(file) {
	let ext = File.GetFileExtension(file.name);
	// TODO svg's are not displaying in html for unknown reason.  If that works,
	// support svg.  Everything else with svg tested.
	if (!["jpeg", "jpg", "png", "gif"].includes(ext)) {
		Cyphrme.Error("unsupported image type " + ext);
	}

	if (isEmpty(Login.UAD) || !Cyphrme.IsCyphrmeDigest(Login.UAD)) {
		Cyphrme.Error("User must be logged in.");
	}
	// TODO Pass to Coze.Normal().
	let pay = await GenPayStandard();
	// Required fields 
	pay.typ = Typs.TypUserProfilePictureUpdate;
	pay.id = Login.UAD;
	pay.profile_picture = await Lib.HashFile(file); // Empty alg defaults to "SHA-256".;
	return GenCoze(pay);
};


////////////////////////////////////////////////////////////////////////////////
// Comment
////////////////////////////////////////////////////////////////////////////////

/**
commentCreate returns a required pay for the given comment typ. No optional
fields in the returned CommentPay is populated.
@param   {string}      typ
@param   {CommentPay}  cp
@param   {boolean}     edit=false
@returns {Coze}
 */
async function commentCreate(typ, cp, edit) {
	let pay = await GenPayStandard();
	pay.typ = typ;
	if (edit) {
		pay.id = cp.id;
	}
	pay.root = cp.root;
	delete cp.root;
	// Optional fields. If 'parent' is set, comment is a reply. Replies have the
	// digest of the text being replied to (`rtd`).
	for (let field in cp) {
		pay[field] = cp[field];
	}
	return GenCoze(pay);
};

/**
CommentCreate returns a Coze for creating a comment.
NOTE: pay has all comment/review fields that are being signed over,
directly inside of pay. Server unmarshaller puts it in it's correct form.
@param     {CommentPay} cp
@returns   {Coze}
@protected {normal=[canon,option]}
 */
async function CommentCreate(cp) {
	return commentCreate(Typs.TypCommentCreate, cp);
};

/**
CommentUpdate returns a Coze for updating/editing a Comment/Review.
@param     {CommentPay} cp
@returns   {Coze}
@protected {normal=[canon,option]}
 */
async function CommentUpdate(cp) {
	return commentCreate(Typs.TypCommentUpdate, cp, true);
};

/**
Creates a Coze for deleting a comment.
@param     {B64}  id  Comment ID
@returns   {Coze}
@protected {normal=[only]}
 */
async function CommentDelete(id) {
	return GenCoze({
		typ: Typs.TypCommentDelete,
		id: id,
	});
};


////////////////////////////////////////////////////////////////////////////////
// File/Image
////////////////////////////////////////////////////////////////////////////////

/**
FileCreate returns a signed Coze action for creating a file (which may be an
image). Returns an signed Coze with these pay fields: 
{"alg", "iat", "tmb","typ", "id","root","ext","file_name", child_ac}
@param     {object} file       File Object
@param     {B64}    [root]     Optional root if file is attached to something.
@param     {B64}    [child_ac] Optional child_ac if root is a model's child.
@returns   {Coze}
@protected {normal=[only]}
 */
async function FileCreate(file, root, child_ac) {
	let pay = await GenPayStandard();
	pay.typ = Typs.TypFileCreate;
	pay.id = await Lib.HashFile(file, Login.CozeKey.alg)
	pay.ext = File.GetFileExtension(file.name)
	pay.file_name = file.name;

	// Only sign root if given.
	if (!isEmpty(root)) {
		pay.root = root;
	}
	// Only sign child_ac if given.
	if (!isEmpty(child_ac)) {
		pay.child_ac = child_ac;
	}
	return GenCozeWithMeta(pay);
};

/**
FileDelete returns a signed Coze action for deleting a file.
@param     {B64}   czd  File czd
@returns   {Coze}
@protected {normal=[only]}
 */
async function FileDelete(czd) {
	return GenCoze({
		typ: Typs.TypFileDelete,
		id: czd
	});
};

// /**
//  * Creates a Coze for creating an image. `img` contains the digest of the image.
//  * img.root is the root subject, and the target subject. TODO Typedef.
//  * 
//  * @param     {object} img  Image Object
//  * @returns   {Coze}
//  * @protected {normal=[only]}
//  */
// async function ImageCreate(img) {
// 	let pay = await GenPayStandard();
// 	pay.typ = "cyphr.me/ac/image/create";
// 	pay.id = img.id;
// 	pay.ext = img.ext;
// 	pay.file_name = img.file_name;
// 	pay.root = img.root;

// 	return GenCozeWithMeta(pay);
// };

// /**
//  * Creates an action for deleting an image.
//  * 
//  * @param     {B64}   czd  Image czd
//  * @returns   {Coze}
//  * @protected {normal=[only]}
//  */
// async function ImageDelete(czd) {
// 	return GenCoze({
// 		id: czd,
// 		typ: "cyphr.me/image/delete"
// 	});
// };