"use strict";

import * as Cyphrme from './cyphrme.js';
import * as Ajax from './ajax.js';

export {
	DeleteTableRow,
	InitRowCheckbox,
	InitTableButtonsToggle,
	NormalizeCheckBoxOptionsRecordMap,
	SetCheckBoxModals,
	SetModalPreview,
}

/**
@typedef {import('../../../pkg/cozejs/typedef.js').B64} B64

A CheckBoxOptions is an object that holds various components that are useful for
tables that support checkbox options.

checkedList: Array of the records that have been checked.

deleteFunc: Function for setting on the modal's delete button.

supportDelete: Whether or not the checkbox options supports deletions.

deleteModal: Bootstrap modal that preview's records for deletion, and holds the
deletion button/function.

extraBtns: Array of HTML Button Elements that can be appended to the table.
.queryClass must be set on the button, which should be the class for querying
the element, prepended with a '.'.

modalFunc: Array of functions for initializing Bootstrap modals that shows a
preview list of records being manipulated. The function must perform logic based
on the CheckBoxOptions object.

recordMap: Map holds the record/row ID as key, and the entirety of the record as
the value. A human readable field 'rowName' is added for expressing details
about the row, if applicable (e.g. 'kid' for keys, 'title' for ACs, etc.). A
second field 'rowID' is added for special cases in which the record's id is not
needed, but another field, such as digest for files. Otherwise, this field
should always be set as the record/row id.
@typedef  {object}        CheckBoxOptions 
@property {B64[]}         checkedList
@property {function}      deleteFunc
@property {boolean}       supportDelete
@property {Modal}         deleteModal
@property {Element[]}     extraBtns
@property {function[]}    modalFunc
@property {object}        recordMap
*/

/**@type {NodeList} */
var delBtns;


/**
InitTableButtonsToggle sets the event listeners for toggling elements
visibility.
@param   {CheckBoxOptions} opts  CheckBoxOptions for the page.
@returns {void}
 */
function InitTableButtonsToggle(opts) {
	if (opts.supportDelete) {
		delBtns = document.querySelectorAll('.table_delete_btn');
	}
	if (!isEmpty(opts.extraBtns)) {
		document.querySelectorAll('.table_buttons').forEach(function (item) {
			// Must create a new element for each append to the page. cloneNode, append,
			// prepend, appendChild, etc. all act like cut and paste, and not copy paste.
			for (let b of opts.extraBtns) {
				let btn = b.cloneNode(true);
				Show(btn);
				btn.addEventListener(b.event, b.event_listener);
				item.appendChild(btn)
			}
		});
		// console.debug(opts);
	}
}


/**
SetCheckBoxModals instantiates the given modals, and set the event listeners.
The delete modal is always on a given table, but only activated when
'CheckBoxOptions.supportDelete' is set to true. All modal instances other than
delete should be in 'CheckBoxOptions.modals'.
@param   {CheckBoxOptions} opts  CheckBoxOptions for holding the modal instances.
@returns {void}
 */
function SetCheckBoxModals(opts) {
	if (opts.supportDelete) {
		setDeleteModal(opts);
	}
	if (!isEmpty(opts.modals)) {
		opts.modals.forEach((item) => item(opts));
	}
}


/**
setDeleteModal sets the delete fields on a CheckBoxOptions on the current page.
The event listeners are also set in this function, that enables the deletions
preview modal, as well as setting the delete button's function that is on the
modal.
@param   {CheckBoxOptions} opts  Holds the relevant data for setting the event listeners for checkbox deletions.
@returns {void}
 */
function setDeleteModal(opts) {
	let dme = document.getElementById('deleteModal');
	let deleteModal = new bootstrap.Modal(dme, {
		keyboard: true
	});

	dme.querySelector('#deleteModalBtn').addEventListener('click', () => opts.deleteFunc());

	document.querySelectorAll('.table_delete_btn').
	forEach((item) => item.addEventListener('click', () => SetModalPreview(opts, deleteModal, "deleteList")));
}


/**
SetModalPreview sets the modal-body list items on a model that supports
previews for manipulations to records in a table that supports checkboxes.
@param   {CheckBoxOptions}    opts              CheckboxOptions for holding recordMap.
@param   {HTMLElement}        bsModalInstance   Modal being manipulated and toggled.
@param   {string}             divListID         Element ID of the div containing the modification list.
@returns {void}
 */
async function SetModalPreview(opts, bsModalInstance, divListID) {
	let list = document.getElementById(divListID);
	list.innerHTML = '';
	// Make a dummy div to append to before triggering the canvas append (which 
	// is much less efficient).
	let items = document.createElement('div');
	for (let r of opts.checkedList) {
		let item = document.createElement('li');
		item.classList.add("list-group-item");
		let name = opts.recordMap[r].rowTitle;
		let id = opts.recordMap[r].rowID;

		item.textContent = id.substring(0, 6) + '...' + ' | ' + name;
		items.append(item);
	}
	list.append(items); // triggers canvas draw
	bsModalInstance.toggle();
}


/**
DeleteTableRow sets new GUI state for the table from server response.
This only works for endpoints that return parsd.obj as an array of IDs.
@param   {object}           response       Server's JSON Success response.
@param   {CheckBoxOptions}  checkBoxOpts   Current page's CheckBoxOptions State/Object.
@returns {void}
 */
function DeleteTableRow(parsd, checkBoxOpts) {
	if (isEmpty(parsd) || isEmpty(parsd.obj)) {
		return
	}
	for (let i = 0; i < parsd.obj.length; i++) {
		document.getElementById(parsd.obj[i]).remove();
		delete checkBoxOpts.recordMap[parsd.obj[i]];
	}
	Cyphrme.Notification("Request was sent successfully", 'success');
}

/**
TODO finish param typedef NormalizeCheckBoxOptionsRecordMap sets the recordMap
on a CheckBoxOptions. The key is the record/row id, with the value being the
entirety of the record, with additional reserved field names added for
application normalization. Fields include:
- rowID:   The record/row's id.
- rowName: The record/row's human readable name (if applicable).

All normalized/needed fields for CheckBoxOptions in the future should live here.
@param   {CheckBoxOptions} opts  Holds the reference for the page's CheckBoxOptions.
@returns {void}
 */
async function NormalizeCheckBoxOptionsRecordMap(opts, record, rowID, rowTitle) {
	opts.recordMap[record.id] = {
		...record
	};
	opts.recordMap[record.id].rowID = rowID; // Helpful for images, since digest is displayed in row
	opts.recordMap[record.id].rowTitle = rowTitle; // Human Readable
}


/**
InitRowCheckbox is for handling events on the HTML buttons for when a checkbox
on a row is changed. Enables/disables the button, depending on the checked list,
and the supported flags. The delete button is supported across all tables, but
is only enabled if CheckBoxOptions.supportDelete is set to true.
@param   {B64}             id    Table Row ID. Should also be the czd of the row record.
@param   {CheckBoxOptions} opts  The Checkbox Options.
@param   {function}        [f]   Optional enable function that must have a signature of (CheckBoxOptions).
@returns {void}
*/
function InitRowCheckbox(id, opts, f) {
	let input = document.getElementById(id).querySelector('input');
	input.disabled = false;
	Show(input);

	// console.debug(opts);
	input.addEventListener('change', function (item) {
		if (item.target.checked) {
			if (!opts.checkedList.includes(id)) {
				opts.checkedList.push(id);
			}
			// If table has delete button, enable
			if (opts.supportDelete) {
				for (let btn of delBtns) {
					Enable(btn)
					Show(btn)
				}
			}
			if (!isEmpty(opts.extraBtns)) {
				opts.extraBtns.forEach((item) => document.querySelectorAll(item.queryClass).forEach((item) => item.disabled = false));
			}
		} else {
			let n = opts.checkedList.indexOf(id);
			opts.checkedList.splice(n, 1);
			if (opts.checkedList.length <= 0) {
				// If table has delete button, disable
				if (opts.supportDelete) {
					for (let btn of delBtns) {
						Disable(btn)
						Hide(btn)
					}
				}
				if (!isEmpty(opts.extraBtns)) {
					opts.extraBtns.forEach((item) => document.querySelectorAll(item.queryClass).forEach((item) => item.disabled = true));
				}
			}
		}

		// Invoke optional enable/disable function
		if (!isEmpty(f)) {
			f(opts);
		}

	});
}