"use strict";

import {
	IsImage,
} from './file.js';


export {
	Dragster,
	DragsterOnload, // Stuttering because app/dragster.js is namespaced to App not Dragster.  
	DragsterSetCallback,
	DragsterSetProgressCallback,
	HandleFiles,
}


///////////////////
// Dragster
////////////////////
function Dragster(el) {
	var b = function(fn, me) {
		return function() {
			return fn.apply(me, arguments);
		};
	};

	this.el = el;
	this.dragenter = b(this.dragenter, this);
	this.dragleave = b(this.dragleave, this);
	this.first = false;
	this.second = false;
	this.el.addEventListener("dragenter", this.dragenter, false);
	this.el.addEventListener("dragleave", this.dragleave, false);
	this.el.addEventListener("drop", this.dragleave, false);
}

Dragster.prototype.dragenter = function(event) {
	if (this.first) {
		return this.second = true;
	} else {
		this.first = true;
		this.customEvent = document.createEvent("CustomEvent");
		this.customEvent.initCustomEvent("dragster:enter", true, true, {
			dataTransfer: event.dataTransfer,
			sourceEvent: event
		});
		return this.el.dispatchEvent(this.customEvent);
	}
};

Dragster.prototype.dragleave = function(event) {
	if (this.second) {
		this.second = false;
	} else if (this.first) {
		this.first = false;
	}
	if (!this.first && !this.second) {
		this.customEvent = document.createEvent("CustomEvent");
		this.customEvent.initCustomEvent("dragster:leave", true, true, {
			dataTransfer: event.dataTransfer,
			sourceEvent: event
		});
		return this.el.dispatchEvent(this.customEvent);
	}
};

Dragster.prototype.removeListeners = function() {
	this.el.removeEventListener("dragenter", this.dragenter, false);
	this.el.removeEventListener("dragleave", this.dragleave, false);
	return this.el.removeEventListener("drop", this.dragleave, false);
};

Dragster.prototype.reset = function() {
	this.first = false;
	return this.second = false;
};



////////////////////////////////////////////////////////////////////////////////
//               Helpful Information for importing/using this module          //
////////////////////////////////////////////////////////////////////////////////

// To use this module,  first set a callback:
// E.g. Dragster.SetCallback(myCallbackFunc);
// 
// A Page must have the following elements loaded for this module to work (as
// expected):
//
// - file_drop_area
// - fileUploadInput

// Example of a File Upload section for a page.
//
// <div id="file_drop_area">
// <div class="card text-dark bg-light mb-3">
// 	<div class="card-header text-white bg-primary">
// 		<i class="bi bi-images"></i> Upload Files
// 	</div>
// 	<div class="card-body">
// 		<form class="file_upload_form">
// 			<p>Upload one or many files with the dialog or by dragging and dropping here</p>
// 			<input class="fileUploadInput" multiple type="file" accept="/*">
// 			<input type="hidden" id="ownerThumb" name="ownerThumb" value="">
// 			<br>
// 			<button class="btn btn-primary btn-lg" id="fileUploadBtn">
// 				<i class="bi bi-search"></i> Select files...
// 			</button>
// 		</form>
// 		<div id="upload_gallery"></div>
// 	</div>
// </div>
// </div>

// File Upload Vars
let dropzone = ""
let dragster
// Name of preview gallery element ID
let gallery = "upload_gallery"

/**
DropFunction is called on a per file basis after drop.  

Optionally async, but must be async for progress to work.  If progress is not
defined, async on the callback is not needed.  
@async
@callback  DropFunction   Called after dragster drop for each file.
@param     {File}         file
*/


/**
ProgressFunction is called on a per file basis with the current file number
after drop and DropFunction are complete.  
@callback  ProgressFunction   Called after dragster drop for each file.
@param     {number}          i
*/


/**
dragsterDropFunction is called with for each file from Files[] after dragster
drop. 
@type {DropFunction}
*/
var dragsterDropFunction

/**
dragsterProgressFunction is called with for each file from Files[] after dragster
drop. 
@type {ProgressFunction}
*/
var dragsterProgressFunction


// Onload for loading page.
async function DragsterOnload() {
	// console.debug("Executing DragsterOnload");
	dropzone = document.getElementById("file_drop_area");
	dragster = new Dragster(dropzone);

	document.addEventListener("dragster:enter", (e) => {
		e.target.classList.add("highlight");
	}, false);
	document.addEventListener("dragster:leave", (e) => {
		e.target.classList.remove("highlight");
	}, false);

	// Handle dropped files
	dropzone.addEventListener('drop', handleDrop, false);

	// Prevent default drag behaviors.
	// For unknown reasons dragover is yanking the drop event it's not stopped.
	// dragenter and dragleave are just for extra measure.    
	['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
		//dropzone.addEventListener(eventName, preventDefaults, false);
		document.body.addEventListener(eventName, preventDefaults, false);
	});

	// Trigger the actual input from the GUI input.
	document.querySelector("#file_drop_area .fileUploadBtn").addEventListener('click', (event) => {
		event.preventDefault();
		document.querySelector("#file_drop_area .fileUploadInput").click();
	});
	// Trigger the file upload event from the GUI File Drop Section.
	document.querySelector("#file_drop_area .fileUploadInput").onchange = (event) => HandleFiles(event.target.files)
};


/**
SetUploadCallbacks sets the global variable for which callback
function to use for the drag and drop upload.
@param   {DropFunction} callback           Called after dragster drop for each file.
@returns {void}
 */
function DragsterSetCallback(callback) {
	dragsterDropFunction = callback;
};


/**
SetUploadCallbacks sets the global variable for which callback
function to use for the drag and drop upload.
@param   {DropFunction} callback           Called after dragster drop for each file.
@returns {void}
 */
function DragsterSetProgressCallback(callback) {
	dragsterProgressFunction = callback;
};



/**
preventDefaults prevents default actions from happening if the event is not
explicitly handled. It also prevents propagation from occurring.
@param   {Event} e      e is the event that is occurring.
@returns {void}
 */
function preventDefaults(e) {
	e.preventDefault();
	e.stopPropagation();
};

/**
handleDrop gets files from the data transfer that happened on the event passed
in. It then passes the files to the helper function: HandleFiles.
@param   {Event} e  an event that occurred for transferring some type of files.
@returns {void}
 */
function handleDrop(e) {
	var dt = e.dataTransfer;
	var files = dt.files;

	HandleFiles(files);
};


/**
HandleFiles accepts variadic files handled.  For each file it calls previewFile,
which displays the files to the page, and the dragster callback.  
@param   {Blob[]} files      Array of image file bytes.
@returns {void}             
 */
async function HandleFiles(files) {
	console.log("HandleFiles", files);
	if (files instanceof DataTransferItemList) {
		let blobs = [];
		for (var index in files) {
			var item = files[index];
			if (item.kind === 'file') {
				var blob = item.getAsFile();
				var reader = new FileReader();
				reader.onload = function(event) {
					console.log(event.target.result); // data url
				};
				reader.readAsDataURL(blob);
				blobs.push(blob)
			}
		}
		files = blobs;
	}
	files = [...files]; // TODO Why?

	for (let i = 0; i < files.length; i++) {
		previewFile(files[i])
		await dragsterDropFunction(files[i])
		if (typeof ProgressFunction === "function") { // ProgressFunction is Optional 
			ProgressFunction(i)
		}
	}
};



/**
previewFile accepts a file, and reads it as a URL (blob).
After the raw file is read, it is then displayed on the page in the
image gallery.
@param   {Blob} file   Raw file bytes being read.
@returns {void}
 */
function previewFile(file) {
	// console.log("previewFile");

	if (IsImage(file.name)) {
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onloadend = function() {
			var item = document.createElement('img');
			item.src = reader.result;
			document.getElementById(gallery).appendChild(item);
		};
		return;
	}
	// console.log("Not an image");
	var item = document.createElement('i');
	item.setAttribute("class", "bi bi-file-earmark-text icon display-1");
	document.getElementById(gallery).appendChild(item);
}




///////////////////////////////
// Original CoffeeScript
// DO NOT DELETE WITHOUT TALKING TO ZAMI.  
///////////////////////////////
// Original Coffeescript output:
//https://github.com/bensmithett/dragster
// Zami personally looked over the lib.  
// Generated by CoffeeScript 1.12.7
// (function() {
// 	var Dragster,
// 		bind = function(fn, me) {
// 			return function() {
// 				return fn.apply(me, arguments);
// 			};
// 		};

// 	Dragster = (function() {
// 		function Dragster(el) {
// 			this.el = el;
// 			this.dragleave = bind(this.dragleave, this);
// 			this.dragenter = bind(this.dragenter, this);
// 			this.first = false;
// 			this.second = false;
// 			this.el.addEventListener("dragenter", this.dragenter, false);
// 			this.el.addEventListener("dragleave", this.dragleave, false);
// 			this.el.addEventListener("drop", this.dragleave, false);
// 		}

// 		Dragster.prototype.dragenter = function(event) {
// 			if (this.first) {
// 				return this.second = true;
// 			} else {
// 				this.first = true;
// 				this.customEvent = document.createEvent("CustomEvent");
// 				this.customEvent.initCustomEvent("dragster:enter", true, true, {
// 					dataTransfer: event.dataTransfer,
// 					sourceEvent: event
// 				});
// 				return this.el.dispatchEvent(this.customEvent);
// 			}
// 		};

// 		Dragster.prototype.dragleave = function(event) {
// 			if (this.second) {
// 				this.second = false;
// 			} else if (this.first) {
// 				this.first = false;
// 			}
// 			if (!this.first && !this.second) {
// 				this.customEvent = document.createEvent("CustomEvent");
// 				this.customEvent.initCustomEvent("dragster:leave", true, true, {
// 					dataTransfer: event.dataTransfer,
// 					sourceEvent: event
// 				});
// 				return this.el.dispatchEvent(this.customEvent);
// 			}
// 		};

// 		Dragster.prototype.removeListeners = function() {
// 			this.el.removeEventListener("dragenter", this.dragenter, false);
// 			this.el.removeEventListener("dragleave", this.dragleave, false);
// 			return this.el.removeEventListener("drop", this.dragleave, false);
// 		};

// 		Dragster.prototype.reset = function() {
// 			this.first = false;
// 			return this.second = false;
// 		};

// 		return Dragster;

// 	})();

// 	if (typeof module === 'undefined') {
// 		window.Dragster = Dragster;
// 	} else {
// 		module.exports = Dragster;
// 	}

// }).call(this);


///////////////////////////////
// Modified CoffeeScript to kill module exports
// DO NOT DELETE WITHOUT TALKING TO ZAMI.  
///////////////////////////////
//https://github.com/bensmithett/dragster
// Zami personally looked over the lib.  
// var Dragster = (function() {


// 	var b = function(fn, me) {
// 		return function() {
// 			return fn.apply(me, arguments);
// 		};
// 	};

// 	function D(el) {
// 		this.el = el;
// 		this.dragleave = b(this.dragleave, this);
// 		this.dragenter = b(this.dragenter, this);
// 		this.first = false;
// 		this.second = false;
// 		this.el.addEventListener("dragenter", this.dragenter, false);
// 		this.el.addEventListener("dragleave", this.dragleave, false);
// 		this.el.addEventListener("drop", this.dragleave, false);
// 	}

// 	D.prototype.dragenter = function(event) {
// 		if (this.first) {
// 			return this.second = true;
// 		} else {
// 			this.first = true;
// 			this.customEvent = document.createEvent("CustomEvent");
// 			this.customEvent.initCustomEvent("dragster:enter", true, true, {
// 				dataTransfer: event.dataTransfer,
// 				sourceEvent: event
// 			});
// 			return this.el.dispatchEvent(this.customEvent);
// 		}
// 	};

// 	D.prototype.dragleave = function(event) {
// 		if (this.second) {
// 			this.second = false;
// 		} else if (this.first) {
// 			this.first = false;
// 		}
// 		if (!this.first && !this.second) {
// 			this.customEvent = document.createEvent("CustomEvent");
// 			this.customEvent.initCustomEvent("dragster:leave", true, true, {
// 				dataTransfer: event.dataTransfer,
// 				sourceEvent: event
// 			});
// 			return this.el.dispatchEvent(this.customEvent);
// 		}
// 	};

// 	D.prototype.removeListeners = function() {
// 		this.el.removeEventListener("dragenter", this.dragenter, false);
// 		this.el.removeEventListener("dragleave", this.dragleave, false);
// 		return this.el.removeEventListener("drop", this.dragleave, false);
// 	};

// 	D.prototype.reset = function() {
// 		this.first = false;
// 		return this.second = false;
// 	};

// 	return D;

// })();